// src/features/product/productActions.js
import { createAction } from '@reduxjs/toolkit';
import {
    getBrands, getCategories, getSubCategories,
    getMedicineForms, getAgeGroups, getDietaryNeeds, getHealthInterest,
    getProducts, getProductDetail, getProductByKeyword
} from '../../services/productService';

// Action Creators
export const clearMessages = createAction('product/clearMessages');

// Get Brands
export const getBrandsStart = createAction('product/getBrandsStart');
export const getBrandsSuccess = createAction('product/getBrandsSuccess');
export const getBrandsFailure = createAction('product/getBrandsFailure');

// Get Categories
export const getCategoriesStart = createAction('product/getCategoriesStart');
export const getCategoriesSuccess = createAction('product/getCategoriesSuccess');
export const getCategoriesFailure = createAction('product/getCategoriesFailure');

// Get Sub-Categories
export const getSubCategoriesStart = createAction('product/getSubCategoriesStart');
export const getSubCategoriesSuccess = createAction('product/getSubCategoriesSuccess');
export const getSubCategoriesFailure = createAction('product/getSubCategoriesFailure');

// Get Medicine Forms
export const getMedicineFormsStart = createAction('product/getMedicineFormsStart');
export const getMedicineFormsSuccess = createAction('product/getMedicineFormsSuccess');
export const getMedicineFormsFailure = createAction('product/getMedicineFormsFailure');

// Get Age Groups
export const getAgeGroupsStart = createAction('product/getAgeGroupsStart');
export const getAgeGroupsSuccess = createAction('product/getAgeGroupsSuccess');
export const getAgeGroupsFailure = createAction('product/getAgeGroupsFailure');

// Get Dietary Needs
export const getDietaryNeedsStart = createAction('product/getDietaryNeedsStart');
export const getDietaryNeedsSuccess = createAction('product/getDietaryNeedsSuccess');
export const getDietaryNeedsFailure = createAction('product/getDietaryNeedsFailure');

// Get Health Interest
export const getHealthInterestStart = createAction('product/getHealthInterestStart');
export const getHealthInterestSuccess = createAction('product/getHealthInterestSuccess');
export const getHealthInterestFailure = createAction('product/getHealthInterestFailure');

// Get Products
export const getProductsStart = createAction('product/getProductsStart');
export const getProductsSuccess = createAction('product/getProductsSuccess');
export const getProductsFailure = createAction('product/getProductsFailure');

// Get Products by Keyword
export const getProductsByKeywordStart = createAction('product/getProductsByKeywordStart');
export const getProductsByKeywordSuccess = createAction('product/getProductsByKeywordSuccess');
export const getProductsByKeywordFailure = createAction('product/getProductsByKeywordFailure');


// Get Product Details
export const getProductDetailStart = createAction('product/getProductDetailStart');
export const getProductDetailSuccess = createAction('product/getProductDetailSuccess');
export const getProductDetailFailure = createAction('product/getProductDetailFailure');


// Thunks
export const fetchBrands = (data) => async (dispatch) => {
    dispatch(getBrandsStart());
    try {
        const response = await getBrands(data);
        if(response.data.status === false){
            dispatch(getBrandsFailure(response.data));
        }else{
            dispatch(getBrandsSuccess(response.data));
        }
    } catch (error) {
        dispatch(getBrandsFailure(error.message));
    }
};

export const fetchCategories = (data) => async (dispatch) => {
    dispatch(getCategoriesStart());
    try {
        const response = await getCategories(data);
        if(response.data.status === false){
            dispatch(getCategoriesFailure(response.data));
        }else{
            dispatch(getCategoriesSuccess(response.data));
        }        
    } catch (error) {
        dispatch(getCategoriesFailure(error.message));
    }
};

export const fetchSubCategories = (data) => async (dispatch) => {
    dispatch(getSubCategoriesStart());
    try {
        const response = await getSubCategories(data);
        if(response.data.status === false){
            dispatch(getSubCategoriesFailure(response.data));
        }else{
            dispatch(getSubCategoriesSuccess(response.data));
        }  
    } catch (error) {
        dispatch(getSubCategoriesFailure(error.message));
    }
};

export const fetchMedicineForms = (data) => async (dispatch) => {
    dispatch(getMedicineFormsStart());
    try {
        const response = await getMedicineForms(data);
        if(response.data.status === false){
            dispatch(getMedicineFormsFailure(response.data));
        }else{
            dispatch(getMedicineFormsSuccess(response.data));
        } 
    } catch (error) {
        dispatch(getMedicineFormsFailure(error.message));
    }
};

export const fetchAgeGroups = (data) => async (dispatch) => {
    dispatch(getAgeGroupsStart());
    try {
        const response = await getAgeGroups(data);
        if(response.data.status === false){
            dispatch(getAgeGroupsFailure(response.data));
        }else{
            dispatch(getAgeGroupsSuccess(response.data));
        }
    } catch (error) {
        dispatch(getAgeGroupsFailure(error.message));
    }
};

export const fetchDietaryNeeds = (data) => async (dispatch) => {
    dispatch(getDietaryNeedsStart());
    try {
        const response = await getDietaryNeeds(data);
        if(response.data.status === false){
            dispatch(getDietaryNeedsFailure(response.data));
        }else{
            dispatch(getDietaryNeedsSuccess(response.data));
        }
    } catch (error) {
        dispatch(getDietaryNeedsFailure(error.message));
    }
};


export const fetchHealthInterest = (data) => async (dispatch) => {
    dispatch(getHealthInterestStart());
    try {
        const response = await getHealthInterest(data);
        if(response.data.status === false){
            dispatch(getHealthInterestFailure(response.data));
        }else{
            dispatch(getHealthInterestSuccess(response.data));
        }
    } catch (error) {
        dispatch(getHealthInterestFailure(error.message));
    }
};


export const fetchProducts = (data) => async (dispatch) => {
    dispatch(getProductsStart());
    try {
        const response = await getProducts(data);
        if(response.data.status === false){
            dispatch(getProductsFailure(response.data));
        }else{
            dispatch(getProductsSuccess(response.data));
        }
    } catch (error) {
        dispatch(getProductsFailure(error.message));
    }
};


export const fetchProductsByKeyword = (data) => async (dispatch) => {
    dispatch(getProductsByKeywordStart());
    try {
        const response = await getProductByKeyword(data);
        if(response.data.status === false){
            dispatch(getProductsByKeywordFailure(response.data));
        }else{
            dispatch(getProductsByKeywordSuccess(response.data));
        }
    } catch (error) {
        dispatch(getProductsByKeywordFailure(error.message));
    }
};


export const fetchProductDetail = (data) => async (dispatch) => {
    dispatch(getProductDetailStart());
    try {
        const response = await getProductDetail(data);
        if(response.data.status === false){
            dispatch(getProductDetailFailure(response.data));
        }else{
            dispatch(getProductDetailSuccess(response.data));
        }
    } catch (error) {
        dispatch(getProductDetailFailure(error.message));
    }
};
