import React, { useEffect } from "react";
import Header from '../component/Header'
import OurBlogArticle from '../component/OurBlogArticle'  
import Testimonials from '../component/Testimonials' 
import BlogDetailComp from '../component/BlogDetailComp' 
import ATC from '../component/ATC' 

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { clearMessages, fetchBlogDetails } from "../features/blog/blogActions";
import { toast } from "react-toastify";


function BlogDetails() {

    // Get the slug from the URL
    const { slug } = useParams();
    
    const dispatch = useDispatch();
    const { errorMessage, blogDetails } = useSelector((state) => state.blog);
  
    useEffect(() => {
      dispatch(fetchBlogDetails(slug));
    }, [dispatch, slug]);
  
    useEffect(() => {
      if (errorMessage) {
        toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
        dispatch(clearMessages()); // Reset the messages after showing the toast
      }
    }, [errorMessage, dispatch]);

  return (
    <>
      <div className='main blog-details-page'>
        <Header/>   
        <BlogDetailComp blogDetails={blogDetails} />  
        {(blogDetails !== null) && (
          <OurBlogArticle blogTitle="Related Articles" blogCategoryId={blogDetails?.blog_category_id} />   
        )}      
        <Testimonials className="border-bottom-none" />     
        <ATC />
      </div>
    </>
  )
}
export default BlogDetails


