import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import classNames from "classnames";
import FilterBtn from "../assets/images/filter-icon.svg";
import CloseIcon from "../assets/images/close-white.svg";
// import ProductListingComp from './ProductListingComp';
import { Link, useLocation } from "react-router-dom";

import Select, { components } from "react-select";
import ShowMoreProductsIcon from "../assets/images/show-more-products-icon.svg";
import { formatPrice } from '../helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMedicineForms,
  fetchAgeGroups,
  fetchDietaryNeeds,
  fetchProducts,
} from "../features/product/productActions";


const CustomSvg = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 9.5L12 15.5L5 9.5"
      stroke="#00A991"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const customComponents = {
  DropdownIndicator: (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CustomSvg /> {/* Replace default DropdownIndicator with CustomSvg */}
      </components.DropdownIndicator>
    );
  },
};
const customStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    color: "var(--color-secondary)",
    opacity: "0.62",
    fontSize: "clamp(14px, 0.833vw, 16px)",
    fontWeight: "500",
  }),
  valueContainer: (provided, state) => ({
    ...provided,

    padding: "0",
    borderRadius: "0",
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "var(--color-gray)" : "var(--color-gray)",
    boxShadow: null,
    minWidth: "216px",
    padding: "11px 16px",
    borderRadius: "12px",
    "&:hover": {
      borderColor: state.isFocused ? "var(--color-gray)" : "var(--color-gray)",
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    animation: "fadeIn 0.5s ease-in-out",
    margin: "5px 0 0",
    padding: 0,
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "var(--color-primary)"
      : state.isFocused
      ? "var(--color-primary-light)"
      : "var(--color-white)",
    color: state.isSelected
      ? "var(--color-white)"
      : state.isFocused
      ? "var(--color-secondary)"
      : "var(--color-secondary)",
    fontSize: "clamp(14px, 0.833vw, 16px)",
    fontWeight: "500",
    margin: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "var(--color-white)",
    padding: "0",
    borderRadius: "12px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0",
  }),
};

function ProductList() {
  // State to manage pagination
  const [page, setPage] = useState(1);

  // State to store all fetched products
  const [allProducts, setAllProducts] = useState([]);

  // visible show more
  const [isVisibleShowMore, setIsVisibleShowMore] = useState(true);

  const [isVisible, setIsVisible] = useState(false);

  // Add sortByOption state
  const [sortByOption, setSortByOption] = useState(null);

  const [hasActiveFilters, setHasActiveFilters] = useState(false);
  const [viewType, setViewType] = useState("three-column-list");

  const [selectedFilters, setSelectedFilters] = useState({
    medicineForm: [],
    ageGroup: [],
    gender: [],
    dietaryNeed: [],
    healthInterest: [],
  });
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleClick = (newViewType) => {
    setViewType(newViewType);
  };

  const dispatch = useDispatch();
  const location = useLocation(); // Get the current location to access the URL query string
  const {
    loading,
    productFetchLoading,
    categories,
    medicineForms,
    brands,
    ageGroups,
    dietaryNeeds,
    healthInterests,
    products,
  } = useSelector((state) => state.product);

  useEffect(() => {
    let data = { page: 1, limit: 100 };
    dispatch(fetchMedicineForms(data));
    dispatch(fetchAgeGroups(data));
    dispatch(fetchDietaryNeeds(data));
  }, [dispatch]);

  // Parse the query string and auto-select checkboxes
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const getFilterValues = (key) => {
      return params.get(key) ? params.get(key).split(",") : [];
    };

    setSelectedFilters({
      categories: getFilterValues("categories"),
      medicineForms: getFilterValues("medicineForms"),
      ageGroups: getFilterValues("ageGroups"),
      brands: getFilterValues("brands"),
      dietaryNeeds: getFilterValues("dietaryNeeds"),
      healthInterests: getFilterValues("healthInterests"),
    });

    // Set sortBy option from query string (if available)
    setSortByOption(params.get("sortBy") || null);

    // Check if any filters or sortBy are active
    const hasFilters = [
      "categories",
      "medicineForms",
      "brands",
      "ageGroups",
      "dietaryNeeds",
      "healthInterests",
    ].some((key) => params.get(key));
    const hasSortBy = !!params.get("sortBy");

    setHasActiveFilters(hasFilters || hasSortBy); // Set visibility based on presence of filters or sortBy
  }, [location.search]);

  // Function to clear all filters and sortBy
  const clearAllFilters = () => {
    setSelectedFilters({
      categories: [],
      medicineForms: [],
      brands: [],
      ageGroups: [],
      dietaryNeeds: [],
      healthInterests: [],
    });
    setSortByOption(null);
    window.history.replaceState(null, "", ""); // Clear the query string
  };

  // Handle filter and sortBy change
  const handleFilterChange = (type, value) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilter = prevFilters[type].includes(value)
        ? prevFilters[type].filter((item) => item !== value)
        : [...prevFilters[type], value];
      return { ...prevFilters, [type]: updatedFilter };
    });
  };
  

  // Convert selected filters and sortBy to query string
  const getQueryString = (filters, sortBy) => {
    const filterString = Object.keys(filters)
      .map((key) => {
        if (filters[key].length > 0) {
          return `${key}=${filters[key].join(",")}`; // Join values with commas
        }
        return "";
      })
      .filter(Boolean) // Remove empty strings
      .join("&"); // Join each key-value pair with &

    const sortByString = sortBy ? `sortBy=${sortBy}` : ""; // Add sortBy to the query string

    const queryString = [filterString, sortByString].filter(Boolean).join("&");
    return queryString ? `?${queryString}` : ""; // Only prepend ? if query string exists
  };

  // Call getProduct when filters or sortBy changes or on component mount
  useEffect(() => {
    let queryString = getQueryString(selectedFilters, sortByOption);
  
    // Ensure queryString does not start with a "?"
    if (queryString.startsWith("?")) {
      queryString = queryString.slice(1);
    }
  
    // Update URL with query string (optional)
    if (queryString) {
      setAllProducts([]); 
      window.history.replaceState(null, "", `?${queryString}`); // Add "?" only once
      setHasActiveFilters(true);
    } else {
      setAllProducts([]);
      setHasActiveFilters(false);
      window.history.replaceState(null, "", window.location.pathname); // Reset to base path
    }
    

    const debounceFetch = setTimeout(() => {
      const data = {
        page: 1,
        limit: 12,
        filters: JSON.stringify(selectedFilters),
        sortBy: (sortByOption) ? sortByOption : '',
      };
      dispatch(fetchProducts(data));
    }, 100); // Adjust debounce time (in milliseconds) as needed

    return () => clearTimeout(debounceFetch); // Cleanup previous debounce
  }, [selectedFilters, sortByOption, dispatch]);

  // Append new products to allProducts state when `products` in Redux changes
  useEffect(() => {
    if (products?.data !== undefined) {
      setAllProducts((prevProducts) => [...prevProducts, ...products.data]);
      if (products.data.length >= 12) {
        setIsVisibleShowMore(true);
      } else {
        setIsVisibleShowMore(false);
      }
    } else {
      setIsVisibleShowMore(false);
    }
  }, [products]);

  // Load more products function
  const loadMoreProducts = () => {
    const nextPage = page + 1;
    let data = { 
      page: nextPage, 
      limit: 12, 
      filters: JSON.stringify(selectedFilters),
      sortBy: (sortByOption) ? sortByOption : '',
    }; // Adjust as needed
    dispatch(fetchProducts(data));
    setPage(nextPage); // Update the page number
  };

  const options = [
    { value: "bestseller", label: "Sort by : Bestseller" },
    { value: "low-to-high", label: "Sort by : Low to High" },
    { value: "high-to-low", label: "Sort by : High to Low" },
    { value: "newest-first", label: "Sort by : Newest First" },
  ];

  return (
    <>
      <section className="product">
        <Row>
          <Col lg="4" xl="3">
            <div
              className={classNames("filter-box", {
                "filter-box--visible": isVisible,
              })}
            >
              <div className="filter-box-header">
                <h3>Filter</h3>
                {hasActiveFilters && (
                  <Link onClick={clearAllFilters} className="cutsom-bttn">
                    Clear All
                  </Link>
                )}
              </div>

              <div className="filter-box-body">
                <div className="filter-box-group">
                  <h3>Product Categories</h3>
                  <div className="checkbox-group">
                    {categories?.data?.map((row, index) => (
                      <div
                        className="form-check custom-checkbox"
                        key={"ca" + index}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={row.slug}
                          id={row.slug}
                          checked={selectedFilters?.categories?.includes(
                            row.slug
                          )}
                          onChange={() =>
                            handleFilterChange("categories", row.slug)
                          }
                        />
                        <label className="form-check-label" htmlFor={row.slug}>
                          {row.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="filter-box-group">
                  <h3>Medicine Form</h3>
                  <div className="checkbox-group">
                    {medicineForms?.data?.map((row, index) => (
                      <div
                        className="form-check custom-checkbox"
                        key={"me" + index}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={row.slug}
                          id={row.slug}
                          checked={selectedFilters?.medicineForms?.includes(
                            row.slug
                          )}
                          onChange={() =>
                            handleFilterChange("medicineForms", row.slug)
                          }
                        />
                        <label className="form-check-label" htmlFor={row.slug}>
                          {row.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="filter-box-group">
                  <h3>Age</h3>
                  <div className="checkbox-group">
                    {ageGroups?.data?.map((row, index) => (
                      <div
                        className="form-check custom-checkbox"
                        key={"ag" + index}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={row.slug}
                          id={row.slug}
                          checked={selectedFilters?.ageGroups?.includes(row.slug)}
                          onChange={() =>
                            handleFilterChange("ageGroups", row.slug)
                          }
                        />
                        <label className="form-check-label" htmlFor={row.slug}>
                          {row.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="filter-box-group">
                  <h3>Brands</h3>
                  <div className="checkbox-group">
                    {brands?.data?.map((row, index) => (
                      <div
                        className="form-check custom-checkbox"
                        key={"ba" + index}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={row.slug}
                          id={row.slug}
                          checked={selectedFilters?.brands?.includes(row.slug)}
                          onChange={() =>
                            handleFilterChange("brands", row.slug)
                          }
                        />
                        <label className="form-check-label" htmlFor={row.slug}>
                          {row.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="filter-box-group">
                  <h3>Dietary Needs</h3>
                  <div className="checkbox-group">
                    {dietaryNeeds?.data?.map((row, index) => (
                      <div
                        className="form-check custom-checkbox"
                        key={"di" + index}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={row.slug}
                          id={row.slug}
                          checked={selectedFilters?.dietaryNeeds?.includes(
                            row.slug
                          )}
                          onChange={() =>
                            handleFilterChange("dietaryNeeds", row.slug)
                          }
                        />
                        <label className="form-check-label" htmlFor={row.slug}>
                          {row.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="filter-box-group">
                  <h3>Health Interest</h3>
                  <div className="checkbox-group">
                    {healthInterests?.data?.map((row, index) => (
                      <div
                        className="form-check custom-checkbox"
                        key={"he" + index}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={row.slug}
                          id={row.slug}
                          checked={selectedFilters?.healthInterests?.includes(
                            row.slug
                          )}
                          onChange={() =>
                            handleFilterChange("healthInterests", row.slug)
                          }
                        />
                        <label className="form-check-label" htmlFor={row.slug}>
                          {row.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="8" xl="9">
            <div className="product-box">
              <div className="product-box-header">
                <div className="product-box-header-left">
                  <h2>Our Products</h2>
                  {allProducts.length > 0 && (
                    <p>{allProducts.length} Products</p>
                  )}
                </div>
                <div className="product-box-header-right">
                  <div className="view-type-group">
                    <button
                      onClick={() => handleClick("three-column-list")}
                      className={
                        viewType === "three-column-list"
                          ? "three-column active"
                          : "three-column"
                      }
                    >
                      <svg
                        width="32"
                        height="33"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24 27.1667V5.83333"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M16 27.1667V5.83333"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M8 27.1667V5.83333"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => handleClick("four-column-list")}
                      className={
                        viewType === "four-column-list"
                          ? "four-column active"
                          : "four-column"
                      }
                    >
                      <svg
                        width="32"
                        height="33"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.667 27.1667V5.83333"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M24 27.1667V5.83333"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M13.333 27.1667V5.83333"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M8 27.1667V5.83333"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => handleClick("list-view-list")}
                      className={
                        viewType === "list-view-list"
                          ? "list-view active"
                          : "list-view"
                      }
                    >
                      <svg
                        width="32"
                        height="33"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26.6665 9.83333L5.33317 9.83333"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M20 16.5H5.33333"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M12 23.1667H5.33333"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <Select
                    defaultValue={sortByOption}
                    onChange={(option) => setSortByOption(option.value)} // Update sortByOption on change
                    options={options}
                    styles={customStyles}
                    placeholder="Sort by:"
                    components={customComponents}
                  />
                </div>
              </div>
              <div className={`product-list ${viewType}`}>
                {allProducts?.length > 0 ? (
                  <ul>
                    {allProducts.map((product, index) => (
                      <li className="product-item" key={"product-" + index}>
                        <Link to={"/products/"+ product.slug}>
                          <div className="product-item-img">
                            <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${product.image}`} alt="" />
                            {product.is_new && (
                              <span>New</span>
                            )}
                          </div>
                          <div className="product-item-text">
                            <p className="category">{product.category_title}</p>
                            <h3>
                              {product.title}
                            </h3>
                            <p className="text-content">
                              {product.short_description}
                            </p>
                            <div className="price-box">
                              <span>Price</span>
                              <div className="price-value">
                                <p className="new-price">{product.currency+formatPrice(product.sale_price)}</p>
                                <del className="old-price">{product.currency+formatPrice(product.regular_price)}</del>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <>
                  {(allProducts.length === 0 && productFetchLoading === false && loading === false) && <p>No products found for the selected filters.</p>}
                  </>
                  // <p className={( loading || productFetchLoading ) ? "d-none" : ""}>
                  //   No products found for the selected filters.
                  // </p>
                )}
              </div>
              {!productFetchLoading && (
                <button
                  onClick={loadMoreProducts}
                  className={`cutsom-bttn ${
                    !isVisibleShowMore ? "d-none" : ""
                  }`}
                >
                  Show More Products <img src={ShowMoreProductsIcon} alt="" />
                </button>
              )}
            </div>
          </Col>
        </Row>
        <Link onClick={toggleVisibility} className="filter-bttn">
          <img src={isVisible ? CloseIcon : FilterBtn} alt="" />
        </Link>
      </section>
    </>
  );
}
export default ProductList;
