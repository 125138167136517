import React, { useState, useEffect } from "react";

import Select, { components } from "react-select";
import ShowMoreProductsIcon from "../assets/images/show-more-products-icon.svg";
import { Link } from "react-router-dom";
import { formatPrice } from "../helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import { fetchWishlistProducts } from "../features/wishlist/wishlistActions";

const CustomSvg = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 9.5L12 15.5L5 9.5"
      stroke="#00A991"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const customComponents = {
  DropdownIndicator: (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CustomSvg /> {/* Replace default DropdownIndicator with CustomSvg */}
      </components.DropdownIndicator>
    );
  },
};
const customStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    color: "var(--color-secondary)",
    opacity: "0.62",
    fontSize: "clamp(14px, 0.833vw, 16px)",
    fontWeight: "500",
  }),
  valueContainer: (provided, state) => ({
    ...provided,

    padding: "0",
    borderRadius: "0",
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "var(--color-gray)" : "var(--color-gray)",
    boxShadow: null,
    minWidth: "216px",
    padding: "11px 16px",
    borderRadius: "12px",
    "&:hover": {
      borderColor: state.isFocused ? "var(--color-gray)" : "var(--color-gray)",
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    animation: "fadeIn 0.5s ease-in-out",
    margin: "5px 0 0",
    padding: 0,
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "var(--color-primary)"
      : state.isFocused
      ? "var(--color-primary-light)"
      : "var(--color-white)",
    color: state.isSelected
      ? "var(--color-white)"
      : state.isFocused
      ? "var(--color-secondary)"
      : "var(--color-secondary)",
    fontSize: "clamp(14px, 0.833vw, 16px)",
    fontWeight: "500",
    margin: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "var(--color-white)",
    padding: "0",
    borderRadius: "12px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0",
  }),
};

function ProductListingComp() {
  // State to manage pagination
  const [page, setPage] = useState(1);

  // State to store all fetched products
  const [allProducts, setAllProducts] = useState([]);

  // visible show more
  const [isVisibleShowMore, setIsVisibleShowMore] = useState(true);

  // Add sortByOption state
  const [sortByOption, setSortByOption] = useState('');

  const [viewType, setViewType] = useState("three-column-list");

  const handleClick = (newViewType) => {
    setViewType(newViewType);
  };

  const dispatch = useDispatch();
  const { loading, wishlistItems } = useSelector((state) => state.wishlist);

  useEffect(() => {
    let data = { page: 1, limit: 12, sort_by: sortByOption };
    dispatch(fetchWishlistProducts(data));
  }, [dispatch, sortByOption]);



   // Re-fetch products when wishlistItems or page changes
   useEffect(() => {
    if (wishlistItems?.data !== undefined) {
      if (page === 1) {
        setAllProducts(wishlistItems.data); // Set products for the first page
      } else {
        setAllProducts((prevProducts) => [...prevProducts, ...wishlistItems.data]); // Append new products
      }

      setIsVisibleShowMore(wishlistItems.data.length >= 12); // Control "Show More" visibility
    } else {
      setIsVisibleShowMore(false);
    }
  }, [wishlistItems, page]);



  // Load more products function
  const loadMoreProducts = () => {
    const nextPage = page + 1;
    const data = { page: nextPage, limit: 12, sort_by: sortByOption };
    dispatch(fetchWishlistProducts(data));
    setPage(nextPage); // Update the page number
  };

  const options = [
    { value: "bestseller", label: "Sort by : Bestseller" },
    { value: "low-to-high", label: "Sort by : Low to High" },
    { value: "high-to-low", label: "Sort by : High to Low" },
    { value: "newest-first", label: "Sort by : Newest First" },
  ];

  return (
    <>
      <div className="product-box">
        <div className="product-box-header">
          <div className="product-box-header-left">
            <h2>Wishlist</h2>
            {allProducts.length > 0 && <p>{allProducts.length} Products</p>}
          </div>
          <div className="product-box-header-right">
            <div className="view-type-group">
              <button
                onClick={() => handleClick("three-column-list")}
                className={
                  viewType === "three-column-list"
                    ? "three-column active"
                    : "three-column"
                }
              >
                <svg
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 27.1667V5.83333"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M16 27.1667V5.83333"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M8 27.1667V5.83333"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <button
                onClick={() => handleClick("four-column-list")}
                className={
                  viewType === "four-column-list"
                    ? "four-column active"
                    : "four-column"
                }
              >
                <svg
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.667 27.1667V5.83333"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M24 27.1667V5.83333"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M13.333 27.1667V5.83333"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M8 27.1667V5.83333"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <button
                onClick={() => handleClick("list-view-list")}
                className={
                  viewType === "list-view-list"
                    ? "list-view active"
                    : "list-view"
                }
              >
                <svg
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.6665 9.83333L5.33317 9.83333"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M20 16.5H5.33333"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M12 23.1667H5.33333"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            </div>
            <Select
              defaultValue={sortByOption}
              onChange={(option) => setSortByOption(option.value)} // Update sortByOption on change
              options={options}
              styles={customStyles}
              placeholder="Sort by:"
              components={customComponents}
            />
          </div>
        </div>

        <div className={`product-list ${viewType}`}>
          {allProducts?.length > 0 ? (
            <ul>
              {allProducts.map((wp, index) => (
                
                <li className="product-item" key={"product-" + index}>
                  <Link to={"/products/" + wp.product.slug}>
                    <div className="product-item-img">
                      <img
                        src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${wp.product.image}`}
                        alt=""
                      />
                      {wp.product.is_new && <span>New</span>}
                    </div>
                    <div className="product-item-text">
                      <p className="category">{wp.product.category_title}</p>
                      <h3>{wp.product.title}</h3>
                      <p className="text-content">
                        {wp.product.short_description}
                      </p>
                      <div className="price-box">
                        <span>Price</span>
                        <div className="price-value">
                          <p className="new-price">
                            {wp.product.currency +
                              formatPrice(wp.product.regular_price)}
                          </p>
                          <del className="old-price">
                            {wp.product.currency + formatPrice(wp.product.sale_price)}
                          </del>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <p className={loading ? "d-none" : ""}>
              No products found in the wishlist.
            </p>
          )}
        </div>
        {!loading && (
          <Link
            to="#"
            onClick={loadMoreProducts}
            className={`cutsom-bttn ${!isVisibleShowMore ? "d-none" : ""}`}
          >
            Show More Products <img src={ShowMoreProductsIcon} alt="" />
          </Link>
        )}
      </div>
    </>
  );
}
export default ProductListingComp;
