import React, { useEffect } from "react";
import Header from '../component/Header'
import SubBanner from '../component/SubBanner' 
import LegalPoliciesComp from '../component/LegalPoliciesComp' 
import ATC from '../component/ATC' 

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { clearMessages, getPageContent } from "../features/visitor/visitorActions";

import { toast } from "react-toastify"; // Import toast

function LegalPolicies() {

  // Get the slug from the URL
  const { slug } = useParams();

  const dispatch = useDispatch();
  const { errorMessage, pageResponse } = useSelector((state) => state.visitor);

  useEffect(() => {
    let data = { code: slug };
    dispatch(getPageContent(data));
  }, [dispatch, slug]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearMessages()); // Reset the messages after showing the toast
    }
  }, [errorMessage, dispatch]);


  return (
    <>
      <div className='main legal-policies-page'>
        <Header/>
        <SubBanner HeaderTitle={pageResponse?.page_name} HeaderSubtitle=""/>  
        <LegalPoliciesComp pageResponse={pageResponse} slug={slug} />      
        <ATC />
      </div>
    </>
  )
}
export default LegalPolicies


