import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import CallUs from "../assets/images/contact-us/CallUs.svg";
import SendUsEmail from "../assets/images/contact-us/SendUsEmail.svg";
import ForInquiries from "../assets/images/contact-us/ForInquiries.svg";
import EmailIcon from "../assets/images/contact-us/mail-icon.svg";
import PhoneIcon from "../assets/images/contact-us/phone-icon.svg";
import SendIcon from "../assets/images/contact-us/send-icon.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useDispatch, useSelector } from "react-redux";
import {
  createContactInquiry,
  clearMessages,
} from "../features/visitor/visitorActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify"; // Import toast
import { formatPhoneNumber } from "../helpers/helpers";

function ContactUsComp(pageResponse) {

  const [contactUsData, setContactUsData] = useState(null);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const { loading, successMessage, errorMessage } = useSelector(
    (state) => state.visitor
  );

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setPhone(formatPhoneNumber(value));
  };

  const validate = () => {
    const errors = {};

    // Check for empty fields
    if (!fullName) errors.fullName = "Full Name is required.";
    if (!email) errors.email = "Email is required.";
    if (!phone) errors.phone = "Phone Number is required.";
    if (!description) errors.description = "Description is required.";

    // Check for valid email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email))
      errors.email = "Invalid email format.";


    if (phone && phone.length !== 12)
      errors.phone = "Please enter valid phone number.";

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Initial blank errors
    setErrors({});

    // Validate form inputs
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Dismiss any currently visible toasts
    toast.dismiss();

    // Dispatch registration action
    let data = {
      name: fullName,
      email: email,
      mobile_number: phone,
      description: description,
    };

    dispatch(createContactInquiry(data));
  };

  // Show success and error toasts
  useEffect(() => {
    if (successMessage) {
        toast.success(successMessage, { autoClose: 3000, hideProgressBar: true });
        dispatch(clearMessages()); // Reset the messages after showing the toast
        setFullName("");
        setEmail("");
        setPhone("");
        setDescription("");
        setErrors({});
    }
    if (errorMessage) {
        toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
        dispatch(clearMessages()); // Reset the messages after showing the toast
    }
  }, [successMessage, errorMessage, dispatch]);

  useEffect(() => {
    if (pageResponse?.pageResponse) {
      let conData = pageResponse?.pageResponse?.contact_us_section;
      if(conData !== undefined){
        conData = JSON.parse(conData);
        setContactUsData(conData);
        //console.log(conData)
      }
    }
  }, [pageResponse]);
  

  return (
    <>
      <section className="contact-us">
        <div className="common-header">
          <h2>{contactUsData?.title}</h2>
          <p>
           {contactUsData?.description}
          </p>
        </div>
        <div className="contact-us-main">
          <Row>
            <Col lg="4" className="mb-4 mb-lg-0">
              <div className="contact-us-box">
                <div className="contact-us-box-img">
                  <img src={CallUs} alt="" />
                </div>
                <div className="contact-us-box-info">
                  <h3>Call Us</h3>
                  <p>{contactUsData?.call_us_title}</p>
                  <a href={`tel:${contactUsData?.call_us_no}`} className="phone-number">
                    {contactUsData?.call_us_no}
                  </a>
                  <p className="strong">
                    {contactUsData?.call_us_time}
                  </p>
                  <a href={`tel:${contactUsData?.call_us_no}`} className="cutsom-bttn" title="">
                    <img src={PhoneIcon} alt="" /> Call Now
                  </a>
                </div>
              </div>
            </Col>
            <Col lg="4" className="mb-4 mb-lg-0">
              <div className="contact-us-box">
                <div className="contact-us-box-img">
                  <img src={SendUsEmail} alt="" />
                </div>
                <div className="contact-us-box-info">
                  <h3>Send Us an Email</h3>
                  <p>
                    {contactUsData?.email_us_title}
                  </p>
                  <p className="strong">
                    {contactUsData?.email_us_time}
                  </p>
                  <a
                    href={`mailto:${contactUsData?.email_us_no}`}
                    className="cutsom-bttn"
                    title=""
                  >
                    <img src={EmailIcon} alt="" /> Email Now
                  </a>
                </div>
              </div>
            </Col>
            <Col lg="4" className="mb-4 mb-lg-0">
              <div className="contact-us-box">
                <div className="contact-us-box-img">
                  <img src={ForInquiries} alt="" />
                </div>
                <div className="contact-us-box-info">
                  <h3>For Inquiries</h3>
                  <p>
                    <b>{contactUsData?.inquiries_title}</b>
                  </p>
                  <p className="mb-0">
                    <a href={`tel:${contactUsData?.inquiries_no}`}>{contactUsData?.inquiries_no}</a>
                  </p>
                  <p>
                    <a href={`mailto:${contactUsData?.inquiries_email}`}>{contactUsData?.inquiries_email}</a>
                  </p>
                  <p>
                    <b>{contactUsData?.inquiries_international_title}</b>
                  </p>
                  <p className="mb-0">
                    <a href={`tel:${contactUsData?.inquiries_international_no}`}>{contactUsData?.inquiries_international_no}</a>
                  </p>
                  <p>
                    <a href={`mailto:${contactUsData?.inquiries_international_email}`}>{contactUsData?.inquiries_international_email}</a>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="contact-us-form">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="4">
                <Form.Group className="mb-4" controlId="formFullNamel">
                  <Form.Label>
                    Full Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    isInvalid={!!errors.fullName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fullName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>
                    Email Address <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-4" controlId="formBasicPhoneNumbber">
                  <Form.Label>
                    Phone Number <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Phone Number"
                    maxLength={12}
                    value={phone}
                    onChange={handlePhoneChange}
                    //onChange={(e) => setPhone(e.target.value)}
                    isInvalid={!!errors.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="mb-3" controlId="formDescription">
                  <Form.Label>
                    Description <span>*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Write here..."
                    rows={3}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    isInvalid={!!errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
                <p className="note">
                  *PLEASE NOTE : We are not able to discuss medical conditions,
                  prescription medication or possible medication interactions.
                  We strongly encourage you to speak with a nutritionally
                  informed physician or pharmacist regarding possible medication
                  and supplement interactions.
                </p>

                <Button className="cutsom-bttn" type="submit" disabled={loading}>
                  {loading ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      size="lg"
                      className="spinner-icon"
                    />
                  ) : (
                    <>
                      <img src={SendIcon} alt="Send" /> Submit
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </section>
    </>
  );
}
export default ContactUsComp;
