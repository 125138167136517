import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button, Form } from 'react-bootstrap'
import { CDBStepper, CDBStep, CDBBtn } from "cdbreact"
import modalClose from '../assets/images/modal-close.svg'
import buttonArowPrimary from '../assets/images/button-arrow-primary.svg'
import buttonArowWhite from '../assets/images/button-arrow.svg'
import BackIcon from '../assets/images/back-icon-white.svg'
function CareerForm() {
    const [active, setActive] = useState(1);
  
    const handleNextPrevClick = (a: number) => setActive(a);
    

  return (
    <>
       <div className="career-form">
        <div className="career-form-header">
          <p className='career-form-header-top'>Apply here</p>
          <h3>Please fill your basic details.</h3>
        </div>
        <CDBStepper steps={[]}>          
          <CDBStep
            id={1}
            icon="check"
            name="My Information"
            handleClick={() => handleNextPrevClick(2)}
            active={active}
            component={<Step1 handleNextPrevClick={handleNextPrevClick} />}
          />
          <CDBStep
            id={2}
            icon="check"
            name="Voluntary Disclosures"
            handleClick={() => handleNextPrevClick(3)}
            active={active}
            component={<Step2 handleNextPrevClick={handleNextPrevClick} />}
          />
          <CDBStep
            id={3}
            icon="check"
            name="Self Identity"
            handleClick={() => handleNextPrevClick(4)}
            active={active}
            component={<Step3 handleNextPrevClick={handleNextPrevClick} />}
          />
        </CDBStepper>
      </div>
    </>
  )
}

export default CareerForm

const Step3 = ({ handleNextPrevClick, ...props } : any) => {
 
    return (
      <div className="StepContainer">      
        <div className="FlexColumnContainer" >    
          <Form>
            
            <Form.Group className="mb-4 form-group">
              <Form.Label>Please confirm your veteran status :</Form.Label>
              <select className="form-select"  aria-label="Default select example">
                <option>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </Form.Group>
            <Form.Group className="mb-4 form-group">
              <Form.Label>Please confirm your veteran status :</Form.Label>
              <select className="form-select"  aria-label="Default select example">
                <option>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </Form.Group>
            <Form.Group className="mb-4 form-group">
              <Form.Label>Are you Hispanic or Latino :  </Form.Label>
              <select className="form-select"  aria-label="Default select example">
                <option>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </Form.Group>
            <Form.Group className="mb-4 form-group">
              <Form.Label>Please select the race/ethnicity that best applies to you :</Form.Label>
              <select className="form-select"  aria-label="Default select example">
                <option>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </Form.Group>
            <Form.Group className="mb-3 form-group  w-full">
              <div className="check-group">
                  <input id='CheckMeOut' type="checkbox" />
                  <Form.Label className="w-full" htmlFor="CheckMeOut">Yes, I have read and consent to the <a href="/">terms and conditions</a>.</Form.Label>                
              </div>
            </Form.Group>        
            <div className="d-flex justify-content-between w-full  mt-4">
              <CDBBtn className="cutsom-bttn back-bttn" onClick={() => handleNextPrevClick(3)}>
               <img src={BackIcon} alt="" /> Back
              </CDBBtn>
              <CDBBtn className="cutsom-bttn" onClick={() => handleNextPrevClick(4)}>
                Finish <img className='on-hover' src={buttonArowWhite} alt="" />
              </CDBBtn>
            </div>
          </Form>
        </div>
      </div>
        
    );
  };
  
  const Step2 = ({ handleNextPrevClick, ...props }: any) => {
    //  complete form
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    //  disability
    const [showDisability, setShowDisability] = useState(false);
    const handleDisabilityClose = () => setShowDisability(false);
    const handleDisabilityShow = () => setShowDisability(true);
  
    //  Terms and Conditions
    const [showTermsConditions, setShowTermsConditions] = useState(false);
    const handleTermsConditionsClose = () => setShowTermsConditions(false);
    const handleTermsConditionsShow = () => setShowTermsConditions(true);
    
    return (
      <div className="StepContainer">      
        <div className="FlexColumnContainer" >
          <Form>          
            <Form.Group className="mb-3 form-group w-full">
              <Form.Label className='checkbox-header'>Please check one of the boxes below  if you have a disability?<span>*</span></Form.Label>
              <div className="radio-group">
                  <input id='checkOne'  name='radio' type="radio"  />
                  <Form.Label htmlFor="checkOne">Yes, I have a disability, or have had one in the past</Form.Label>
                  <input id='checkTwo' name='radio' type="radio"  />
                  <Form.Label  htmlFor="checkTwo">No, I do not have a disability and have not had one in the past</Form.Label>
                  <input id='checkThree' name='radio' type="radio"  />
                  <Form.Label  htmlFor="checkThree">I do not want to answer</Form.Label>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 form-group w-full">
              <div className='form-info'>
                <p>Why are you being asked to complete this form? <a href="/"  onClick={handleShow} >Know more <img src={buttonArowPrimary} alt="" /></a></p>
                <p>How do you know if you have a disability? <a href="/" onClick={handleDisabilityShow} >Know more <img src={buttonArowPrimary} alt="" /></a></p>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 form-group w-full">
              <div className="check-group">
                  <input id='CheckMeOut' type="checkbox" />
                  <Form.Label className="w-full" htmlFor="CheckMeOut">Yes, I have read and consent to the <a href="/" onClick={handleTermsConditionsShow}>terms and conditions</a>.</Form.Label>                
              </div>
            </Form.Group>
            <div className="d-flex justify-content-between w-full mt-4">
              <CDBBtn className="cutsom-bttn   back-bttn" onClick={() => handleNextPrevClick(2)}>
              <img src={BackIcon} alt="" /> Back
              </CDBBtn>
              <CDBBtn className="cutsom-bttn" onClick={() => handleNextPrevClick(4)}>
              Next <img className='on-hover' src={buttonArowWhite} alt="" />
              </CDBBtn>
            </div>
          </Form>          
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="xl"
          className='common-modal'
          backdropClassName="common-backdrop-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Why are you being asked to complete this form? </Modal.Title>
             <Button onClick={handleClose}>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <p>We are a federal contractor or subcontractor. The law requires us to provide equal employment opportunity to qualified people with disabilities. We have a goal of having at least 7% of our workers as people with disabilities. The law says we must measure our progress towards this goal. To do this, we must ask applicants and employees if they have a disability or have ever had one. People can become disabled, so we need to ask this question at least every five years.</p>
            <p>Completing this form is voluntary, and we hope that you will choose to do so. Your answer is confidential. No one who makes hiring decisions will see it. Your decision to complete the form and your answer will not harm you in any way. If you want to learn more about the law or this form, visit the U.S. Department of Labor’s Office of Federal Contract Compliance Programs (OFCCP) website at www.dol.gov/ofccp. How do you know if you have a disability</p>
          </Modal.Body>
        </Modal>
  
        <Modal
          show={showTermsConditions}
          onHide={handleTermsConditionsClose}
          backdrop="static"
          keyboard={false}
          size="xl"
          className='common-modal'
          backdropClassName="common-backdrop-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Terms and Conditions</Modal.Title>
             <Button onClick={handleTermsConditionsClose}>
              <img src={modalClose} alt="" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <p>We collects the following categories of personal information to contact you and evaluate your application for employment: Name and Address; Email Address; Phone Number; Occupation and Education.</p>
            <p>I understand that any misrepresentation or material omission made by me on this application will be sufficient cause for cancellation of this application or immediate discharge from the employer's service, whenever it is discovered.</p>
            <p>I give the employer the right to contact and obtain information from all references, employers, educational institutions, and to otherwise verify the accuracy of the information contained in this application. I hereby release from liability the employer and its representatives for seeking, gathering and using such information and all other persons, corporations or organizations for furnishing such information.</p>
            <p>If I am hired, I understand that I am free to resign at any time with or without cause and without prior notice, and the employer reserves the same right to terminate my employment at any time, with or without cause and without prior notice, except as may be required by law. This application does not constitute a contract for employment for any specified period. I understand that no representative of the employer, other than an authorized officer, has the authority to make any assurances to the contrary. I further understand that any such assurances must be in writing and signed by an authorized officer.</p>
            <p>I understand it is this company's policy not to refuse to hire a qualified individual with a disability because of that person's need for a reasonable accommodation as required by the ADA.</p>
            <p>I understand that if I am hired, I will be required to provide proof of identity and legal work authorization.</p>
            <p>I understand that I am not obligated to disclose sealed or expunged records of conviction or arrest.</p>
          </Modal.Body>
        </Modal>
  
        <Modal
          show={showDisability}
          onHide={handleDisabilityClose}
          backdrop="static"
          keyboard={false}
          size="xl"
          className='common-modal'
          backdropClassName="common-backdrop-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>How do you know if you have a disability? </Modal.Title>
             <Button onClick={handleDisabilityClose}>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <p>A disability is a condition that substantially limits one or more of your “major life activities.” If you have or have ever had such a condition, you are a person with a disability. <span>Disabilities include, but are not limited to:</span></p>
            <div className="disabilities">
              <ul>
                <li>Alcohol or other substance use  disorder (not currently using  drugs illegally)</li>
                <li>Autoimmune disorder, for  example, lupus, fibromyalgia,  rheumatoid arthritis, HIV/AIDS</li>
                <li>Blind or low vision</li>
                <li>Cancer (past or present)</li>
                <li>Cardiovascular or heart  disease</li>
                <li>Celiac disease</li>
                <li>Cerebral palsy</li>
                <li>Deaf or serious difficulty  hearing</li>
                <li>Diabetes</li>
              </ul>
              <ul>
                <li>Disfigurement, for example,  disfigurement caused by burns,  wounds, accidents, or congenital  disorders</li>
                <li>Epilepsy or other seizure disorde</li>
                <li>Gastrointestinal disorders, for example,  Crohn's Disease, irritable bowel  syndrome</li>
                <li>Intellectual or developmental disability</li>
                <li>Mental health conditions, for example,  depression, bipolar disorder, anxiety  disorder, schizophrenia, PTSD</li>
                <li>Missing limbs or partially missing limbs</li>
                <li>Mobility impairment, benefiting from the  use of a wheelchair, scooter, walker,  leg brace(s) and/or other supports</li>
              </ul>
              <ul>
                <li>Nervous system condition, for example,  migraine headaches, Parkinson’s  disease, multiple sclerosis (MS)</li>
                <li>Neurodivergence, for example,  attention-deficit/hyperactivity disorder  (ADHD), autism spectrum disorder,  dyslexia, dyspraxia, other learning  disabilities</li>
                <li>Partial or complete paralysis (any  cause)</li>
                <li>Pulmonary or respiratory conditions, for  example, tuberculosis, asthma,  emphysema</li>
                <li>Short stature (dwarfism)</li>
                <li>Traumatic brain injury</li>
              </ul>
              </div>
          </Modal.Body>
        </Modal>
      </div>
        
    );
  };
  
  const Step1 = ({ handleNextPrevClick, ...props } : any) => {
    return (
      <div className="StepContainer">
        <div className="FlexColumnContainer" >
          <Form>
            <Form.Group className="mb-3 form-group">
              <input className="form-control" type="text" placeholder="First Name*" />         
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <input className="form-control" type="text" placeholder="Last Name*" />         
            </Form.Group>
            <Form.Group className="mb-3 form-group" controlId="formBasicEmail">
              <input className="form-control" type="email" placeholder="Email Address*" />         
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <input className="form-control" type="tel" placeholder="Phone Number*" />
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <input className="form-control" type="text" placeholder="Job Title*" />         
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <div className="file-control">
                <input className="form-control " type="file" id="ChooseFile" placeholder="No file chosen*" />   
                <div className='d-flex align-items-center'><Form.Label htmlFor="ChooseFile">Choose File</Form.Label> <span>No file chosen</span></div>
              </div>
  
            </Form.Group>
            <Form.Group className="mb-3 form-group w-full">
              <textarea name="" rows={5} placeholder='Tell us about your self*'></textarea>        
            </Form.Group>         
            <Form.Group className="mb-4 form-group w-full">
              <div className="check-group">
                  <input id='CheckMeOut' type="checkbox" />
                  <Form.Label className="w-full" htmlFor="CheckMeOut">Yes, I have read and consent to the <a href="/">terms and conditions</a>.</Form.Label>                
              </div>
            </Form.Group>
            <div className="d-flex justify-content-end w-full  mt-4">
              <CDBBtn className="cutsom-bttn" onClick={() => handleNextPrevClick(2)}>
              Next <img className='on-hover' src={buttonArowWhite} alt="" />
              </CDBBtn>
            </div>
          </Form> 
        </div>
      </div>
    );
  };
  









