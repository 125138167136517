// src/store/rootReducer.js
import { combineReducers } from 'redux';
import authReducer from '../features/auth/authReducer';
import visitorReducer from '../features/visitor/visitorReducer';
import blogReducer from '../features/blog/blogReducer';

import userReducer from '../features/user/userReducer';
import productReducer from '../features/product/productReducer';
import wishlistReducer from '../features/wishlist/wishlistReducer';

import geoLocationReducer from '../features/geo/geoLocationReducer';
import cartReducer from '../features/cart/cartReducer';

import checkoutReducer from '../features/checkout/checkoutReducer';
import orderReducer from '../features/order/orderReducer';




const rootReducer = combineReducers({
    auth: authReducer,
    visitor: visitorReducer,
    blog: blogReducer,
    user: userReducer,
    product: productReducer,
    geo: geoLocationReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    checkout: checkoutReducer,
    order: orderReducer,
    
    // Add other reducers here
});

export default rootReducer;