import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import CancelledIcon from "../assets/images/profile/shield.svg";
import DeliveredIcon from "../assets/images/profile/delivered.svg";
import TrackOrderIcon from "../assets/images/profile/map.svg";
import OrderReturnIcon from "../assets/images/profile/order-return.svg";
// import CartImg from '../assets/images/cart/cart-img.png'
import BackImg from "../assets/images/cart/back-icon.svg";
import InvoiceImg from "../assets/images/profile/invoice.svg";
import MapIcon from "../assets/images/profile/map-color.svg";
import MAilIcon from "../assets/images/profile/mail-fill.svg";
import masterCardIcon from "../assets/images/profile/master-card.svg";
import PhoneIcon from "../assets/images/profile/phone.svg";
import { Link } from "react-router-dom";

// import TooltipIcon from "../assets/images/cart/tooltip.svg";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";

// import { Rating } from "react-simple-star-rating";

import ShowMoreOrdersIcon from "../assets/images/show-more-products-icon.svg";
import { formatPrice, formatDate, addressStringCreate, formatPhoneNumber } from "../helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderList,
  clearOrderMessages,
} from "../features/order/orderActions";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const CartSummary = ({ orderDetail }) => {
  
  const discountTotal =  orderDetail?.order_items.reduce((sum, item) => sum + item.variation_regular_price, 0);

  return (
    <div className="summary-box order-summary-box">
      <div className="box-header">
        <h3>Summary</h3>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
      </div>
      <h4>Order Summary</h4>
      <Table>
        <tbody>
          <tr>
            <td>Items Total({orderDetail?.order_items.length})</td>
            <td>{orderDetail?.currency+formatPrice(orderDetail?.order_amount)}</td>
          </tr>

          {discountTotal > 0 && (
          <tr>
            <td>Discounts</td>
            <th>-{orderDetail?.currency+formatPrice(discountTotal)}</th>
          </tr>
          )}

          {orderDetail?.promocode_obj &&(
          <tr>
            <td>PromoCode ({orderDetail?.promocode_obj?.code})</td>
            <th>-{orderDetail?.currency+formatPrice(orderDetail?.discount_amount)}</th>
          </tr>
          )}

          {orderDetail?.tax_amount > 0 && (
          <tr>
            <td>Tax</td>
            <td className="discounts">+{orderDetail?.currency+formatPrice(orderDetail?.tax_amount)}</td>
          </tr>
          )}

          <tr>
            <th>Order Total</th>
            <th>{orderDetail?.currency+formatPrice(orderDetail?.total_amount)}</th>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

function MyOrdersComp() {
  const [orderDetail, setOrderDetail] = useState("");

  // const [rating, setRating] = useState(0);
  // const handleRating = (rate: number) => {
  //   setRating(rate);
  // };

  // const [validated, setValidated] = useState(false);

  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }
  //   setValidated(true);
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.stopPropagation();
  //   } else {
  //     // Proceed with form submission
  //   }
  //   setValidated(true);
  // };

  const [isActive, setIsActive] = useState(false);
  const handleToggle = (order) => {
    setOrderDetail(order);
    setIsActive((prevState) => !prevState);
  };

  // State to manage pagination
  const [page, setPage] = useState(1);

  // State to store all fetched orders
  const [allOrders, setAllOrders] = useState([]);

  // visible show more
  const [isVisibleShowMore, setIsVisibleShowMore] = useState(true);

  const dispatch = useDispatch();
  const { loading, errorMessage, orderList } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    setAllOrders([]);
    let data = { page: 1, limit: 3 };
    dispatch(fetchOrderList(data));
  }, [dispatch]);

  useEffect(() => {
    if (orderList?.data) {
      setAllOrders((prev) => [...prev, ...orderList.data]);
      setIsVisibleShowMore(orderList.data.length >= 3);
    } else {
      setIsVisibleShowMore(false);
    }
  }, [orderList]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearOrderMessages()); // Reset the messages after showing the toast
    }
  }, [errorMessage, dispatch]);

  const loadMoreOrders = () => {
    const nextPage = page + 1;
    let data = { page: nextPage, limit: 3 };
    dispatch(fetchOrderList(data));
    setPage(nextPage); // Update the page number
  };

  const sumOfqty = (orderItems) => {
    return orderItems.reduce((sum, item) => sum + item.qty, 0);
  };

  const displayOrderStatus = (item) => {
    if (item.status_id === 5) {
      return (
        <Link to="" className="cutsom-bttn delivered-bttn">
          <img src={DeliveredIcon} alt="" /> Delivered
        </Link>
      );
    } else if (item.status_id === 6) {
      return (
        <Link to="" className="cutsom-bttn order-return-bttn">
          <img src={OrderReturnIcon} alt="" /> Order Return
        </Link>
      );
    } else if (item.status_id === 7) {
      return (
        <Link to="" className="cutsom-bttn cancelled-bttn">
          <img src={CancelledIcon} alt="" /> Cancelled
        </Link>
      );
    } else {
      // Dynamically add the background color class
      return (
        <Link to="" className={`cutsom-bttn bg-${item.status_bg_color}`}>
          {item.status_title}
        </Link>
      );
    }
  };

  const displayTrackOrderDetails = (item) => {
    if (item.awb_url === "") {
      return null;
    } else {
      return (
        <Link
          to={item.awb_url}
          target="_blank"
          className="cutsom-bttn track-order-bttn"
        >
          <img src={TrackOrderIcon} alt="" /> Track Order
        </Link>
      );
    }
  };

  return (
    <>
      <div className={`${isActive ? "my-order-info isHide" : "my-order-info"}`}>
        <div className="tab-content-header">
          <h3>My Orders</h3>
        </div>

        {allOrders.map((order, index) => (
          <div className="order-info-box" key={order.id}>
            <div
              className="order-info-box-header"
              onClick={() => handleToggle(order)}
            >
              <h4>
                {order.order_number} <span></span> Qty :{" "}
                {sumOfqty(order.order_items)}
              </h4>
              <h5>Order Date : {formatDate(order.created_at)}</h5>
            </div>
            <div className="order-info-box-body">
              {order.order_items.map((item, item_index) => (
                <div className="order-info-box-item" key={item.id}>
                  <div className="cart-box">
                    <div className="cart-box-img">
                      <Link to={"/products/" + item?.product.slug}>
                        <img
                          src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${item?.product.image}`}
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="cart-box-content">
                      <span>{item?.product?.category_title}</span>
                      <h2>
                        <Link to={"/products/" + item?.product.slug}>
                          {item?.product?.title}
                        </Link>
                      </h2>
                      <Link to={"/products/" + item?.product.slug}>
                        {item.variation_title}
                      </Link>
                      &nbsp;&nbsp;|&nbsp;&nbsp;
                      <Link
                        to={"/products/" + item?.product.slug}
                        className="price-value"
                      >
                        {order.currency} {formatPrice(item.selling_price)}
                      </Link>
                    </div>
                  </div>
                  <div className="qty-wrapper">
                    <p className="mb-0"> Qty : {item.qty}</p>
                    <p className="mb-0">
                      {" "}
                      SubTotal : {order.currency}{" "}
                      {formatPrice(item.total_selling_price)}
                    </p>
                  </div>
                  <div className="bttn-group">
                    {displayTrackOrderDetails(item)}
                    {displayOrderStatus(item)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className="d-flex justify-content-center">
          <Link
            to="#"
            onClick={loadMoreOrders}
            className={`cutsom-bttn ${!isVisibleShowMore ? "d-none" : ""}`}
          >
            {loading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="lg"
                className="spinner-icon"
              />
            ) : (
              <>
                Show More Orders <img src={ShowMoreOrdersIcon} alt="" />
              </>
            )}
          </Link>
        </div>
      </div>

      {orderDetail && (
        <div
          className={`${
            isActive ? "order-details-info" : "order-details-info isHide"
          }`}
        >
          <div className="tab-content-header">
            <h3>
              <Link
                to=""
                className="backToList"
                onClick={() => handleToggle(orderDetail)}
              >
                <img src={BackImg} alt="" />
              </Link>{" "}
              Order Details
            </h3>
          </div>
          <div className="order-info-box">
            <div className="order-info-box-header">
              <h4>
                {orderDetail.order_number} <span></span> Qty :{" "}
                {sumOfqty(orderDetail.order_items)}
              </h4>
              <div className="order-info-box-header-right">
                <h5>Order Date : {formatDate(orderDetail.created_at)}</h5>{" "}
                <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${orderDetail.invoice_file}`} download className="cutsom-bttn track-order-bttn">
                  <img src={InvoiceImg} alt="" /> Invoice
                </a>
              </div>
            </div>
            <div className="order-info-box-body">
              {orderDetail.order_items.map((item, item_index) => (
                <div className="order-info-box-item" key={"k"+item.id}>
                  <div className="cart-box">
                    <div className="cart-box-img">
                      <Link to={"/products/" + item?.product.slug}>
                        <img
                          src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${item?.product.image}`}
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="cart-box-content">
                      <span>{item?.product?.category_title}</span>
                      <h2>
                        <Link to={"/products/" + item?.product.slug}>
                          {item?.product?.title}
                        </Link>
                      </h2>
                      <Link to={"/products/" + item?.product.slug}>
                        {item.variation_title}
                      </Link>
                      &nbsp;&nbsp;|&nbsp;&nbsp;
                      <Link
                        to={"/products/" + item?.product.slug}
                        className="price-value"
                      >
                        {orderDetail.currency} {formatPrice(item.selling_price)}
                      </Link>
                    </div>
                  </div>
                  <div className="qty-wrapper">
                    <p className="mb-0"> Qty : {item.qty}</p>
                    <p className="mb-0">
                      {" "}
                      SubTotal : {orderDetail.currency}{" "}
                      {formatPrice(item.total_selling_price)}
                    </p>
                  </div>
                  <div className="bttn-group">
                    {displayTrackOrderDetails(item)}
                    {displayOrderStatus(item)}
                  </div>
                </div>
              ))}
            </div>
            <div className="address-summary">
              <Row>
                <Col lg={6}>
                  <div className="summary-box">
                    <div className="box-header">
                      <h3 className="mb-0">Delivered on this Address</h3>
                    </div>
                    <div className="delivered-box">
                      <div className="delivered-box-img">
                        <img src={MapIcon} alt="" />
                      </div>
                      <div className="delivered-box-content">
                        <h4>
                         {addressStringCreate(orderDetail?.address_obj)}
                        </h4>
                      </div>
                    </div>
                    <div className="box-header">
                      <h3 className="mb-0">Payment Method</h3>
                    </div>
                    <div className="delivered-box method-group">
                      <div className="delivered-box-img">
                        <img src={masterCardIcon} alt="" />
                      </div>
                      <div className="delivered-box-content">
                        <p>Amazon Pay ICICI Bank Credit Card ending in</p>
                        <p className="card-number">**** **** **** 3456</p>
                      </div>
                    </div>
                    <div className="box-header">
                      <h3 className="mb-0">Update Sent to</h3>
                    </div>
                    {orderDetail?.user_obj?.mobile_number && (
                    <div className="delivered-box mb-24">
                      <div className="delivered-box-img">
                        <img src={PhoneIcon} alt="" />
                      </div>
                      <div className="delivered-box-content">
                        <p>
                          <a href={`tel:${formatPhoneNumber(orderDetail.user_obj.mobile_number)}`}>
                            {formatPhoneNumber(orderDetail.user_obj.mobile_number)}
                          </a>
                        </p>
                      </div>
                    </div>
                    )}

                    {orderDetail?.user_obj?.email && (
                    <div className="delivered-box">
                      <div className="delivered-box-img">
                        <img src={MAilIcon} alt="" />
                      </div>
                      <div className="delivered-box-content">
                        <p>
                          <a href={`mailto:${(orderDetail.user_obj.email)}`}>
                            {orderDetail?.user_obj?.email}
                          </a>
                        </p>
                      </div>
                    </div>
                    )}

                    {/* <div className="box-header">
                      <h3 className="mb-0">Rate this Product</h3>
                    </div>
                    <div className="rate-product-box">
                      <div className="mb-30">
                        <Rating
                          onClick={handleRating}
                          initialValue={rating}
                          ratingValue={rating}
                          showTooltip={rating !== 0}
                          tooltipArray={["1", "2", "3", "4", "5"]}
                        />
                      </div>
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <Form.Group
                          controlId="formDescription"
                          className="form-group"
                        >
                          <Form.Label>
                            Add Your Experience <span>*</span>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Write here..."
                            rows={3}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please add add your experience.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-group">
                          <div className="custom-file-input form-control">
                            <input
                              type="file"
                              id="file"
                              className="custom-file-input__input"
                            />
                            <label
                              htmlFor="file"
                              className="custom-file-input__label"
                            >
                              <p>Choose your file</p>
                              <span className="cutsom-bttn">Browse</span>
                            </label>
                          </div>
                        </Form.Group>
                        <Button type="submit" className="cutsom-bttn">
                          Submit
                        </Button>
                      </Form>
                    </div> */}
                  </div>
                </Col>
                <Col lg={6}>
                  {" "}
                  <CartSummary orderDetail={orderDetail} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MyOrdersComp;
