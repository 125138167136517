import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

import BestSeller1 from '../assets/images/b-seller-img-1.png'
import BestSeller2 from '../assets/images/b-seller-img-2.png'
import BestSeller3 from '../assets/images/b-seller-img-3.png'
import BestSeller4 from '../assets/images/b-seller-img-4.png'
import BestSeller5 from '../assets/images/b-seller-img-5.png'
import whiteArrow from '../assets/images/view-all-arrow.svg'
import { Navigation} from 'swiper/modules'
 import SwiperCore from 'swiper'
 SwiperCore.use([Navigation]);
function BestSeller() {
  //const swiperRef = useRef(null);
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  return (
    <>
     <section className='product-slider'>
        <div className="product-slider-header">
           <div className='product-slider-header-left'> 
              <h2>Best Seller</h2>
              <p>Find the perfect products for every chapter.</p>
           </div>
           <a href="/products" className='cutsom-bttn'>View All Products <img src={whiteArrow} alt="" /></a>
        </div>
        <div className='category-list'>
          <ul>
            <li> 
              <Link to="" className='style-1'>Ashwagandha</Link>
            </li>
            <li> 
              <Link to="" className='style-2'>Elderberry</Link>
            </li>
            <li> 
              <Link to="" className='style-3'>Glnkgo Blloba</Link>
            </li>
            <li> 
              <Link to="" className='style-4'>Turmeric</Link>
            </li>
            <li> 
              <Link to="" className='style-1'>Oat</Link>
            </li>
            <li> 
              <Link to="" className='style-2'>Berries</Link>
            </li>
            <li> 
              <Link to="" className='style-3'>Mashrooms</Link>
            </li>
            <li> 
              <Link to="" className='style-4'>Shatavari</Link>
            </li>
            <li> 
              <Link to="" className='style-1'>Horney Goat Weed</Link>
            </li>
            <li> 
              <Link to="" className='style-2'>Mulra Purma</Link>
            </li>
            <li> 
              <Link to="" className='style-3'>Antlo Xldants</Link>
            </li>
          </ul>
        </div>
        <Swiper
        modules={[Navigation]}
        slidesPerView={5}
        spaceBetween={30}
        pagination={{ clickable: true }}
        crollbar={{ draggable: true }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1700: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
        }}
      >
        <SwiperSlide>
          <a href='/' className="product-slider-box">
            <div className="product-slider-box-img">
              <img src={BestSeller1} alt="" />
              <span>New</span>
            </div>
            <div className="product-slider-box-text">
              <p>Gummy</p>
              <h3>Alive!® Zero Sugar Women's Gummy Multivitamin</h3> 
              <div className="price-box">
                <span>Price</span>
                <div className='price-value'>
                  <p className='new-price'>$12.49</p>
                  <del className='old-price'>$16.99</del>
                </div>
              </div>           
            </div>              
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href='/' className="product-slider-box">
            <div className="product-slider-box-img">
              <img src={BestSeller2} alt="" />
              <span>New</span>
            </div>
            <div className="product-slider-box-text">
              <p>Gummy</p>
              <h3>Alive!® Zero Sugar Women's Gummy Multivitamin</h3> 
              <div className="price-box">
                <span>Price</span>
                <div className='price-value'>
                  <p className='new-price'>$12.49</p>
                  <del className='old-price'>$16.99</del>
                </div>
              </div>           
            </div>
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href='/' className="product-slider-box">
            <div className="product-slider-box-img">
              <img src={BestSeller3} alt="" />
              <span>New</span>
            </div>
            <div className="product-slider-box-text">
              <p>Gummy</p>
              <h3>Alive!® Zero Sugar Women's Gummy Multivitamin</h3> 
              <div className="price-box">
                <span>Price</span>
                <div className='price-value'>
                  <p className='new-price'>$12.49</p>
                  <del className='old-price'>$16.99</del>
                </div>
              </div>           
            </div>           
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href='/' className="product-slider-box">
            <div className="product-slider-box-img">
              <img src={BestSeller4} alt="" />
              <span>New</span>
            </div>
            <div className="product-slider-box-text">
              <p>Gummy</p>
              <h3>Alive!® Zero Sugar Women's Gummy Multivitamin</h3> 
              <div className="price-box">
                <span>Price</span>
                <div className='price-value'>
                  <p className='new-price'>$12.49</p>
                  <del className='old-price'>$16.99</del>
                </div>
              </div>           
            </div>         
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href='/' className="product-slider-box">
            <div className="product-slider-box-img">
              <img src={BestSeller5} alt="" />
              <span>New</span>
            </div>
            <div className="product-slider-box-text">
              <p>Gummy</p>
              <h3>Alive!® Zero Sugar Women's Gummy Multivitamin</h3> 
              <div className="price-box">
                <span>Price</span>
                <div className='price-value'>
                  <p className='new-price'>$12.49</p>
                  <del className='old-price'>$16.99</del>
                </div>
              </div>           
            </div>       
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href='/' className="product-slider-box">
            <div className="product-slider-box-img">
              <img src={BestSeller1} alt="" />
              <span>New</span>
            </div>
            <div className="product-slider-box-text">
              <p>Gummy</p>
              <h3>Alive!® Zero Sugar Women's Gummy Multivitamin</h3> 
              <div className="price-box">
                <span>Price</span>
                <div className='price-value'>
                  <p className='new-price'>$12.49</p>
                  <del className='old-price'>$16.99</del>
                </div>
              </div>           
            </div>         
          </a>
        </SwiperSlide>
        <div className='swiper-button-nav'>
        <div ref={navigationPrevRef} className="swiper-button-prev"></div>
        <div ref={navigationNextRef} className="swiper-button-next"></div>
        </div>
      </Swiper>      
     </section>     
    </>
  )
}
export default BestSeller