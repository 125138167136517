import React from 'react'
import Header from '../component/Header'
import SubBanner from '../component/SubBanner'
import CareerComp from '../component/CareerComp'
function Career() {
  return (
    <>
    <div className='main career-page'>
      <Header/>
      <SubBanner HeaderTitle="Career" HeaderSubtitle=""/> 
      <CareerComp />  
    </div>
    </>
  )
}
export default Career


