import React from 'react'
import whiteArrow from '../assets/images/button-arrow.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'

function Wellness({categorySection}) {
  // const swiperRef = useRef(null);
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  
  return (
    <>
     <section className='wellness'>
        <div className="common-header">
            <h2>{categorySection?.title}</h2>
            <p>{categorySection?.description}</p>
        </div>
        <Swiper
        modules={[Navigation]}
        slidesPerView={4}
        spaceBetween={30}
        pagination={{ clickable: true }}
        crollbar={{ draggable: true }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1700: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
      >
        {categorySection?.data?.length > 0 && (
          <>
            {categorySection.data.map((row, index) => (
              <SwiperSlide key={index}>
                <div className="wellness-box">
                  <div className="wellness-box-img">
                    <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${row.image}`} alt="" />
                  </div>
                  <h3>{row.title}</h3>
                  <p>{row.description}</p>
                  <a href={row.link} className='cutsom-bttn'>Shop Now <img src={whiteArrow} alt="" /></a>
                </div>
              </SwiperSlide>
            ))}
          </>
        )}

        
        <div className='swiper-button-nav'>
        <div ref={navigationPrevRef} className="swiper-button-prev"></div>
        <div ref={navigationNextRef} className="swiper-button-next"></div>
        </div>
      </Swiper>
     </section>     
    </>
  )
}
export default Wellness