import React from 'react'
import Header from '../component/Header'
import SubBanner from '../component/SubBanner'
import FAQList from '../component/FAQList' 
function FAQ() {
  return (
    <>
      <div className='main faq-page'>
        <Header/>
        <SubBanner HeaderTitle="FAQ’s" HeaderSubtitle=""/>
        <FAQList />     
      </div>
    </>
  )
}
export default FAQ


