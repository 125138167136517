import React, { useEffect, useState } from "react";
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

import Banner1 from '../assets/images/banner.png'
import whiteArrow from '../assets/images/button-arrow.svg'

import { useDispatch, useSelector } from "react-redux";
import { clearMessages, getSliders } from "../features/visitor/visitorActions";
import { toast } from "react-toastify"; // Import toast

function Banner() {
  
  // State to store all fetched sliders
  const [allSliders, setAllSliders] = useState([]);

  const dispatch = useDispatch();
  const { errorMessage, sliderResponse } = useSelector((state) => state.visitor);

  useEffect(() => {
    let data = { page: 1, limit: 10 };
    dispatch(getSliders(data));
  }, [dispatch]);

  useEffect(() => {
    if (sliderResponse?.data) {
      setAllSliders(sliderResponse.data);
    }
  }, [sliderResponse]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearMessages()); // Reset the messages after showing the toast
    }
  }, [errorMessage, dispatch]);


  return (
    <section className='home-banner'>          
      <Swiper
       modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        crollbar={{ draggable: true }}
      >
        {allSliders.map((slide, index) => (
          <SwiperSlide key={slide.id}>
            <img className='w-100' src={Banner1} alt="" />
            <div className="banner-content-wrap">
                <div dangerouslySetInnerHTML={{__html: slide?.first_line}}></div>
                <div dangerouslySetInnerHTML={{__html: slide?.second_line}}></div>
                <div dangerouslySetInnerHTML={{__html: slide?.third_line}}></div>
                
                <div className="promo-code-wrap">
                  <div className="promo-code">
                    <p>Promocode</p>
                    <h3>{slide?.promocode}</h3>
                  </div>
                  <h2>{slide?.promo_code_title}</h2>
                </div>
                <Link to={slide?.button_url} className='cutsom-bttn'>{slide?.button_label} <img src={whiteArrow} alt="" /></Link>
            </div>
          </SwiperSlide> 
        ))}  
      </Swiper>
    </section>
  )
}
export default Banner