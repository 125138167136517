import React, { useEffect, useState } from 'react'
// import { Row, Col } from 'react-bootstrap'
// import Product1 from '../assets/images/product-1.png'
// import Product2 from '../assets/images/product-2.png'
// import Product3 from '../assets/images/product-3.png'
// import Product4 from '../assets/images/product-4.png'
// import Client1 from '../assets/images/c-logo-1.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay} from 'swiper/modules'
import { Link } from 'react-router-dom'

import { useSelector } from "react-redux";


function HomeTodayOffer({offerSection}) {

  // const swiperRef = useRef(null);
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)


  // State to store all fetched brands
  const [homeBrands, setHomeBrands] = useState([]);

  const { brands } = useSelector((state) => state.product);

  useEffect(() => {
    if(brands?.data?.length > 0) {
      setHomeBrands(brands.data)
    }
  }, [brands]); 


  return (
    <>
     <section className='home-today-offer'>
        <div className="common-header">
            <h2>{offerSection?.title}</h2>
            <p>{offerSection?.description}</p>
        </div>
        <Swiper
        modules={[Navigation]}
        slidesPerView={4}
        spaceBetween={30}
        pagination={{ clickable: true }}
        crollbar={{ draggable: true }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1700: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
      >
        
        {offerSection?.data?.length > 0 && (
          <>
            {offerSection.data.map((row, index) => (
              <SwiperSlide key={index}> {/* Add a unique key */}
                <Link className="today-offer-box" to={row.link}>
                  <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${row.image}`} alt="" />
                  <h3>{row.title}<br/>{row.description}</h3>
                </Link>
              </SwiperSlide>
            ))}
          </>
        )}

        <div className='swiper-button-nav'>
        <div ref={navigationPrevRef} className="swiper-button-prev"></div>
        <div ref={navigationNextRef} className="swiper-button-next"></div>
        </div>
      </Swiper> 
     </section>
     <section className='client-logo'>      
      <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={6}
        loop={true}
        spaceBetween={30}
        pagination={{ clickable: true }}
        crollbar={{ draggable: true }}
        navigation={false}   
        autoplay={{
          delay: 2500, // Adjust delay as needed
          disableOnInteraction: false, // Continue autoplay after interaction
        }} 
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          480: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
          1700: {
            slidesPerView: 6,
            spaceBetween: 30,
          },
        }}
      >
      {homeBrands.slice(0, 10).map((brand, index) => (
          <SwiperSlide key={brand.id}>
              <Link to={`/products?brands=${brand.slug}`}>
                  <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${brand.image}`} alt={brand.title} />
              </Link>
          </SwiperSlide> 
      ))}
      </Swiper>           
     </section>
    </>
  )
}
export default HomeTodayOffer