import React from 'react'
import SubBanner from '../component/SubBanner'  
import CheckoutComp from '../component/CheckoutComp' 
// import MedicineHealthProducts from '../component/MedicineHealthProducts' 
// import OfferSection from '../component/OfferSection' 
// import OurBlogArticle from '../component/OurBlogArticle' 
import ATC from '../component/ATC' 
import Header from '../component/Header'

function Checkout() {
  return (
    <>
      <div className='main cart-page'>
      <Header />
        <SubBanner HeaderTitle="Checkout" HeaderSubtitle=""/> 
        <CheckoutComp />       
        {/* 
        <MedicineHealthProducts />
        <OfferSection />
        <OurBlogArticle blogTitle="Our Blog & Article" /> 
        */}
        <ATC />
      </div>
    </>
  )
}
export default Checkout


