// src/features/user/visitorReducer.js
import { createReducer } from '@reduxjs/toolkit';
import {
    clearMessages,
    createSubscribeStart, createSubscribeSuccess, createSubscribeFailure,
    createContactInquiryStart, createContactInquirySuccess, createContactInquiryFailure,
    getFaqsStart, getFaqsSuccess, getFaqsFailure,
    getPageContentStart, getPageContentSuccess, getPageContentFailure,
    getSliderStart, getSliderSuccess, getSliderFailure,
    getTestimonialStart, getTestimonialSuccess, getTestimonialFailure,
    getAdvertiseStart, getAdvertiseSuccess, getAdvertiseFailure,
    
} from './visitorActions';

const initialState = {
    pageResponse: null,
    faqResponse: null,
    sliderResponse: null,
    testimonialResponse: null,
    advertiseResponse: null,
    loading: false,
    successMessage: null,
    errorMessage: null,
};

const visitorReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(clearMessages, (state) => {
            state.successMessage = null;
            state.errorMessage = null;
        })

        .addCase(getAdvertiseStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getAdvertiseSuccess, (state, action) => {
            state.loading = false;
            state.advertiseResponse = action.payload.data; 
        })
        .addCase(getAdvertiseFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        .addCase(getTestimonialStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getTestimonialSuccess, (state, action) => {
            state.loading = false;
            state.testimonialResponse = action.payload.data; 
        })
        .addCase(getTestimonialFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })


        .addCase(getSliderStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getSliderSuccess, (state, action) => {
            state.loading = false;
            state.sliderResponse = action.payload.data; 
        })
        .addCase(getSliderFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })
        
        .addCase(getFaqsStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getFaqsSuccess, (state, action) => {
            state.loading = false;
            state.faqResponse = action.payload.data; 
        })
        .addCase(getFaqsFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })
    
        .addCase(getPageContentStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getPageContentSuccess, (state, action) => {
            state.loading = false;
            state.pageResponse = action.payload.data; 
        })
        .addCase(getPageContentFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })
        
        .addCase(createSubscribeStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(createSubscribeSuccess, (state, action) => {
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(createSubscribeFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })
        
        .addCase(createContactInquiryStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(createContactInquirySuccess, (state, action) => {
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(createContactInquiryFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        });
});

export default visitorReducer;
