import React, {  useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Rating } from 'react-simple-star-rating'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { Navigation, Thumbs } from 'swiper/modules'
import SwiperCore from 'swiper'
// import BestSeller1 from '../assets/images/b-seller-img-1.png'
// import BestSeller2 from '../assets/images/b-seller-img-2.png'
// import BestSeller3 from '../assets/images/b-seller-img-3.png'
// import BestSeller4 from '../assets/images/b-seller-img-4.png'
// import BestSeller5 from '../assets/images/b-seller-img-5.png'
import LikeIcon from '../assets/images/pd-heart.svg'
import LikeFillIcon from '../assets/images/pd-heart-fill.svg'
import ShareIcon from '../assets/images/pd-share.svg'
import CardIcon from '../assets/images/pd-cart.svg'
import PlusIcon from '../assets/images/pd-plus.svg'
import MinusIcon from '../assets/images/pd-minus.svg'
// import { Link } from 'react-router-dom'
import { formatPrice } from '../helpers/helpers';

import { useDispatch, useSelector } from 'react-redux';
import { clearMessages as clearCartMessages, addToCart } from '../features/cart/cartActions';
import { clearMessages as clearWishlistMessages, addRemoveWishlist } from '../features/wishlist/wishlistActions';
import { toast } from "react-toastify";
import SocialSharingModal from '../component/SocialSharingModal';


SwiperCore.use([Navigation, Thumbs]);

function ProductDetailsComp({productRow}) {
  
  
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  const dispatch = useDispatch();
  const { successMessage: cartSuccessMessage } = useSelector((state) => state.cart);
  const { successMessage: wishlistSuccessMessage } = useSelector((state) => state.wishlist);

  

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  
  // const [rating, setRating] = useState(0)
  
  const [qty, setQty] = useState(1);
  const [isFilled, setIsFilled] = useState(productRow?.is_wishlist);
  const [showSocialShareModal, setShowSocialShareModal] = useState(false);
  const webUrl = window.location.href; // Your web URL to be shared
  const openSocialShareModal = () => setShowSocialShareModal(true);
  const closeSocialShareModal = () => setShowSocialShareModal(false);

  
  // Use state to track the selected variation, defaulting to the first one
  const [selectedVariation, setSelectedVariation] = useState(productRow?.variations?.[0]);
 


  // Function to handle increasing the quantity
  const handleIncrease = () => {
    setQty(prevQty => prevQty + 1);
  };

  // Function to handle decreasing the quantity, ensuring it never goes below 1
  const handleDecrease = () => {
    setQty(prevQty => (prevQty > 1 ? prevQty - 1 : 1));
  };

  // Handle change directly from the input (optional, with validation)
  const handleChange = (event) => {
      const value = Math.max(parseInt(event.target.value, 10), 1);
      setQty(value);
  };

  const handleAddToCart = () => {
      const product = {
          product_id: productRow.id,
          category_title: productRow.category_title,
          title: productRow.title,
          slug: productRow.slug,
          variation_id: selectedVariation.id, // assuming default variation
          price: selectedVariation.sale_price,
          regular_price : selectedVariation.regular_price, //Regular price as discount price
          variation_title: selectedVariation.title,
          currency: productRow.currency,
          qty,
          image: productRow.image,
          is_selected_to_checkout: true
          
      };
      dispatch(addToCart(product));
  };

  const addToWishlist = () => {
    if (!token) {  // Check if token is missing (user is unauthorized)
      toast.error("You are not authorized to add products to the wishlist.", { autoClose: 3000, hideProgressBar: true });
      return false;
    }
    const data = {
      product_id: productRow.id,
    };
    dispatch(addRemoveWishlist(data));
    
  };

  const chooseVariation = (v) =>{
    setSelectedVariation(v);
  }
  

  useEffect(() => {
    if (cartSuccessMessage) {
      toast.success(cartSuccessMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearCartMessages());
    }
  }, [cartSuccessMessage, dispatch]);

  useEffect(() => {
    if (wishlistSuccessMessage) {
      if (wishlistSuccessMessage.includes("added")) {
        setIsFilled(true);  // Fill the heart when product is added
      } else if (wishlistSuccessMessage.includes("removed")) {
        setIsFilled(false); // Unfill the heart when product is removed
      }
      toast.success(wishlistSuccessMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearWishlistMessages());
    }
  }, [wishlistSuccessMessage, dispatch]);

 

  return (
    <>
     <section className='product-details'>        
        <Row>
            <Col lg="5" xl="4">
            <div className="product-list-slider">
              <Swiper
                slidesPerView={1}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                loop={true}
                className="main-swiper"
              >

                {productRow?.image !== "" && (
                  <SwiperSlide key="slide0">
                    <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${productRow.image}`} alt="" />
                  </SwiperSlide>
                )}

                {productRow?.images?.length > 0 && (
                  <>
                    {productRow?.images?.map((imgRow, indexImg) => (
                      <SwiperSlide key={`slide${imgRow.id}`}>
                        <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${imgRow.image}`} alt="" />
                      </SwiperSlide>
                    ))}
                  </>
                )}
              
                <div className='swiper-button-nav'>
                  <div ref={navigationPrevRef} className="swiper-button-prev"></div>
                  <div ref={navigationNextRef} className="swiper-button-next"></div>
                </div>
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={5}
                freeMode={true}
                watchSlidesProgress={true}
                loop={true}
                className="thumbs-swiper"
                breakpoints={{
                  320: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  480: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1500: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                }}
              >
              

                {productRow?.image !== "" && (
                  <SwiperSlide key="thumb0">
                    <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${productRow.image}`}  alt="Thumbnail 0" />
                  </SwiperSlide>
                )}


                {productRow?.images?.length > 0 && (
                  <>
                    {productRow?.images?.map((imgRow, indexImg) => (
                      <SwiperSlide key={`thumb${imgRow.id}`}>
                        <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${imgRow.image}`} alt="" />
                      </SwiperSlide>
                    ))}
                  </>
                )}


              </Swiper>
            </div>
            </Col>
            <Col lg="7" xl="8">
            <div className="product-details-content">
              <div className="product-details-content-header">
                <div className='product-details-content-header-left'>
                  <h2>{productRow?.title}</h2>
                  <span>{productRow?.category_title}</span>
                </div>
                <div className='product-details-content-header-right'>
                  <button className={`like ${isFilled ? 'isFilled' : ''}`} onClick={() => addToWishlist()}>
                    <img className='hear-icon' src={LikeIcon} alt="" />
                    <img className='hear-fill-icon' src={LikeFillIcon} alt="" />
                  </button>
                  <Button onClick={openSocialShareModal} className='share'>
                    <img src={ShareIcon} alt="" />
                  </Button>
                </div>
              </div>
              <div className="product-review">
                {productRow?.reviews?.length > 0 &&
                  <Rating initialValue={formatPrice(productRow?.avg_ratings)} showTooltip allowFraction={true} tooltipDefaultText={(productRow?.avg_ratings)} readonly={true} />                
                }
                {/* <p>Write Review</p> */}
              </div>
              <div className='price-value'>
                <p className='new-price'>{productRow?.currency+formatPrice(productRow?.sale_price)}</p>
                <del className='old-price'>{productRow?.currency+formatPrice(productRow?.regular_price)}</del>
              </div>
              <p className='product-description'>{productRow?.short_description}</p>
              
              {(productRow?.variations?.length > 0) && (
                <div className="size-box">
                  <h3>{productRow?.variations?.[0].attribute_title}</h3>
                  <div className="size-box-group">

                  {productRow?.variations?.map((v, indexVar) => (
                    <div
                      key={v.id}
                      className={`size-box-items ${selectedVariation.id === v.id ? 'isSelected' : ''}`}
                      onClick={() => chooseVariation(v)}  // Set selected variation on click
                    >
                      <p>{productRow?.currency}{formatPrice(v.sale_price)}&nbsp;&nbsp;&nbsp;&nbsp;<del className='text-black'>{productRow?.currency}{formatPrice(v.regular_price)}</del></p> {/* Assuming price is dynamic */}
                      <span>{v.title}</span> {/* Assuming attribute_value is the description like "60 Tablet" */}
                    </div>
                  ))}

                  </div>
                </div>
              )}

              {(productRow?.variations?.length > 0) && (
              <div className="quantity-box">
                <h3>Quantity</h3>
                <div className="quantity-box-group">
                  <div className="quantity-box-input">
                    <div className="plus-minus-input">
                      <button onClick={handleDecrease}><img src={MinusIcon} alt="Minus" /></button>
                      <input className='form-control' type="number" value={qty} onChange={handleChange} min="1" />
                      <button onClick={handleIncrease}><img src={PlusIcon} alt="Plus" /></button>
                    </div>
                  </div>
                  <button  className='cutsom-bttn' onClick={handleAddToCart}><img src={CardIcon} alt="" /> Add to Cart</button>
                </div>
              </div>
              )}
            </div>
            </Col>
        </Row>
     </section>

      <SocialSharingModal
        showSocialShareModal={showSocialShareModal}
        closeSocialShareModal={closeSocialShareModal}
        webUrl={webUrl}
      />     
    </>
  )
}
export default ProductDetailsComp 