import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SocialSharingModal = ({ showSocialShareModal, closeSocialShareModal, webUrl }) => {
  // Social sharing URLs
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(webUrl)}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(webUrl)}`;
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(webUrl)}`;
  const pinterestShareUrl = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(webUrl)}`;
  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(webUrl)}`;

  return (
    <Modal
      show={showSocialShareModal}
      onHide={closeSocialShareModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='social-share-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Social Sharing Modal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md="12">
              <Form.Group className="mb-4">
                <Form.Label>Share this content</Form.Label>
                <p>Choose your preferred social media platform to share.</p>
              </Form.Group>

              {/* Social media buttons */}
              <Form.Group className='link-wrapper'>
                <Button
                  variant="primary"
                  className="mb-2"
                  href={facebookShareUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Share on Facebook
                </Button>
                <Button
                  variant="info"
                  className="mb-2"
                  href={twitterShareUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Share on X(Twitter)
                </Button>
                <Button
                  variant="danger"
                  className="mb-2"
                  href={linkedInShareUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Share on LinkedIn
                </Button>
                
                <Button
                  variant="warning"
                  className="mb-2"
                  href={pinterestShareUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Share on Pinterest
                </Button>
                <Button
                  variant="success"
                  className="mb-2"
                  href={whatsappShareUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Share on WhatsApp
                </Button>
              </Form.Group>
            </Col>

            <Col md="12">
              <div className="bttn-group justify-content-center">
                <Link
                  onClick={closeSocialShareModal}
                  className="cutsom-bttn cancel-bttn"
                  type="button"
                >
                  Cancel
                </Link>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SocialSharingModal;
