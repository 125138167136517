import React from 'react'
import Header from '../component/Header'
import SubBanner from '../component/SubBanner' 
import BlogList from '../component/BlogList' 
import Testimonials from '../component/Testimonials' 
import ATC from '../component/ATC' 
function Blog() {
  return (
    <>
      <div className='main blog-page'>
        <Header/>
        <SubBanner HeaderTitle="Our Blog & Article" HeaderSubtitle=""/>
        <BlogList />
        <Testimonials className="border-bottom-none" />     
        <ATC />
      </div>
    </>
  )
}
export default Blog


