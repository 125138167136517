import React, { useEffect, useState } from "react";
import { Row, Col } from 'react-bootstrap';
import Blog1 from '../assets/images/blog-1.png';
import Calendar from '../assets/images/calendar.svg';
import ShowMoreProductsIcon from '../assets/images/show-more-products-icon.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { clearMessages, fetchBlogs } from "../features/blog/blogActions";
import { toast } from "react-toastify";
import { stripHtmlTags, truncateText, formatDate } from '../helpers/helpers';

function OurBlogArticle() {
  const dispatch = useDispatch();
  const { errorMessage, blogs } = useSelector((state) => state.blog);
  
  // State to manage pagination
  const [page, setPage] = useState(1);

  // State to store all fetched blogs
  const [allBlogs, setAllBlogs] = useState([]);

  useEffect(() => {
    let data = { page: 1, limit: 10, blog_category_id: '' };
    dispatch(fetchBlogs(data));
  }, [dispatch]);

  useEffect(() => {
    if (blogs?.data) {
      setAllBlogs(prevBlogs => [...prevBlogs, ...blogs.data]);
    }
  }, [blogs]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearMessages()); // Reset the messages after showing the toast
    }
  }, [errorMessage, dispatch]);

  const loadMoreBlogs = () => {
    const nextPage = page + 1;
    let data = { page: nextPage, limit: 10, blog_category_id:'' };
    dispatch(fetchBlogs(data));
    setPage(nextPage); // Update the page number
  };

  return (
    <>
      <section className='blog-list'>        
        <Row>
          {allBlogs.map((blog, index) => (
            <Col md="6" lg="3" key={blog.id}>
              <Link to={'/blog-details/'+blog.slug} className="blog-list-box">
                <div className="blog-list-box-img">
                  <img src={Blog1} alt={blog.title} />
                </div>
                <div className="blog-list-box-text">
                  <div className="date">
                    <span><img src={Calendar} alt="" /></span>
                    <p>{formatDate(blog.created_at)}</p>
                  </div>
                  <h3>{blog.title}</h3>
                  <p>
                    {truncateText(stripHtmlTags(blog.description), 100)}
                  </p>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
        {/* Conditionally render the Load More button if there are more blogs */}
        {blogs?.data?.length > 0 && (
          <button onClick={loadMoreBlogs} className='cutsom-bttn'>
            Load More <img src={ShowMoreProductsIcon} alt="" />
          </button>
        )}
      </section>     
    </>
  );
}

export default OurBlogArticle;
