import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import EditIcon from "../assets/images/profile/edit.svg";
import CloseIcon from "../assets/images/profile/close-icon.svg";
import { Link } from "react-router-dom";
import PlusIcon from "../assets/images/profile/plus.svg";

import { useDispatch, useSelector } from "react-redux";
import { getAddresses, deleteAddresses } from "../features/user/userActions";
import { formatPhoneNumber, addressStringCreate } from "../helpers/helpers";

import AddAddressModal from "./AddAddressModal";
import ConfirmModal from "./ConfirmModal";
import EditAddressModal from "./EditAddressModal";

function AddressBookComp() {
  const [showAddAddressModal, setShowAddAddressModal] = useState(false);
  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const [editAddress, setEditAddress] = useState("");

  const [showConfirm, setShowConfirm] = useState(false);
  const [addressToRemove, setAddressToRemove] = useState(null);

  const dispatch = useDispatch();
  const { addresses } = useSelector((state) => state.user);

  useEffect(() => {
    if (showAddAddressModal === false || showEditAddressModal === false) {
      let data = { page: 1, limit: 25 };
      dispatch(getAddresses(data));
    }
  }, [dispatch, showAddAddressModal, showEditAddressModal]);

  const handleConfirmRemove = () => {
    dispatch(async (dispatch) => {
      await dispatch(deleteAddresses({ id: addressToRemove }));
      dispatch(getAddresses({ page: 1, limit: 25 }));
      setShowConfirm(false);
    });
  };

  const handleRemoveClick = (addressId) => {
    setAddressToRemove(addressId);
    setShowConfirm(true); // Show the confirmation modal
  };

  const handleCancelRemove = () => {
    setShowConfirm(false);
  };

  const handleEditAddress = (addressData) => {
    setEditAddress(addressData);
    setShowEditAddressModal(true);
  };

  return (
    <>
      <div>
        <div className="tab-content-header">
          <h3>Address Book</h3>
          <Link
            to=""
            onClick={() => setShowAddAddressModal(true)}
            className="cutsom-bttn"
          >
            <img src={PlusIcon} alt="" /> Add New Address
          </Link>
        </div>
        <Row>
          {addresses?.data?.map((row, index) => (
            <Col lg="6" key={index}>
              <div className="address-box">
                <div className="address-box-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <small className="md-0 text-success">
                        {row.address_type === 1 ? "Home" : "Office"}
                      </small>
                      <h3>{row.name}</h3>
                    </div>
                    <div className="address-button-group">
                      {row.is_default === 1 && (
                        <p className="cutsom-bttn default-bttn">Default</p>
                      )}
                      <Link
                        onClick={() => handleEditAddress(row)}
                        className="cutsom-bttn me-2"
                      >
                        <img className="m-0" src={EditIcon} alt="" />
                      </Link>
                      <Link
                        onClick={() => handleRemoveClick(row.id)}
                        className="danger-bttn"
                      >
                        <img className="m-0" src={CloseIcon} alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="address-box-body">
                  <p>{addressStringCreate(row)}</p>
                  <div className="mail-phone">
                    <a href={`tel:${row.mobile_number}`}>
                      {formatPhoneNumber(row.mobile_number)}
                    </a>
                    <a className="text-lowercase" href={`mailto:${row.email}`}>{row.email}</a>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <AddAddressModal
        show={showAddAddressModal}
        onHide={() => setShowAddAddressModal(false)}
      />
      {editAddress !== "" && (
        <EditAddressModal
          show={showEditAddressModal}
          onHide={() => setShowEditAddressModal(false)}
          addressData={editAddress}
        />
      )}

      <ConfirmModal
        show={showConfirm}
        onConfirm={handleConfirmRemove}
        onCancel={handleCancelRemove}
        message="Are you sure you want to remove this address?"
      />
    </>
  );
}
export default AddressBookComp;
