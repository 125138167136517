import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import ChangePasswordIcon from "../assets/images/password-update.svg";
import BackIcon from "../assets/images/back-icon.svg";

import { useDispatch, useSelector } from "react-redux";
import { verifyEmail, resetPassword, clearMessages } from "../features/auth/authActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify"; // Import toast
import { useLocation } from "react-router-dom";

function ChangePassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [emailToken, setEmailToken] = useState("");
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  
  
  const dispatch = useDispatch();
  const location = useLocation();

  // Extract the page name from the pathname
  const pageName = location.pathname.split("/")[1]; // This will get "verify-email"
  
  const { loading, successMessage, errorMessage } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get("token");
    setEmailToken(tokenParam);
  }, [location.search]);

  useEffect(() => {
    if (emailToken && !isTokenVerified) {
      dispatch(verifyEmail(emailToken));
    }
  }, [emailToken, dispatch, isTokenVerified]);

  useEffect(() => {
    if (successMessage && isChangePassword) {
      toast.success(successMessage, { autoClose: 3000, hideProgressBar: true });
      setPassword("");
      setConfirmPassword("");
      setConfirmPassword(false);
      setIsTokenVerified(false);
      setTimeout(() => (window.location.href = "/sign-in"), 3000);
      dispatch(clearMessages());
    }

    if(successMessage && pageName === 'verify-email'){
      toast.success(successMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearMessages());
      setTimeout(() => (window.location.href = "/sign-in"), 3000);
    }

    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearMessages());
    }
  }, [successMessage, errorMessage, isChangePassword, pageName, dispatch]);

  const validate = () => {
    const errors = {};

    // Check for empty fields
    if (!password) errors.password = "Password is required.";
    if (password !== confirmPassword)
      errors.confirmPassword = "Passwords must match.";

    // Check password length
    if (password && password.length < 8)
      errors.password = "Password must be at least 8 characters long.";

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Initial blank errors
    setErrors({});

    // Validate form inputs
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Dismiss any currently visible toasts
    toast.dismiss();

    // set is change password to true
    setIsChangePassword(true);

    // Dispatch reset password action
    let data = {
      token: emailToken,
      password: password,
    };
    dispatch(resetPassword(data));
  };

  

  return (
    <>
      <Header />
      <div className="sign-in-main password-main">
        <div className="sign-box">
          {pageName !== "verify-email" ? (
            <div className="sign-box-wrap">
              <Row>
                <Col lg="12" className="text-center">
                  <img
                    className="forgot-password-icon"
                    src={ChangePasswordIcon}
                    alt=""
                  />
                  <h2 className="text-center">Update New Password</h2>
                  <p className="text-center">
                    Please set a new password for smooth sign in process.
                  </p>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicConfirmPassword"
                    >
                      <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        isInvalid={!!errors.confirmPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="button-group">
                      <Link
                        className="cutsom-bttn back-btn"
                        to="/forgot-password"
                      >
                        <img src={BackIcon} alt="" />
                      </Link>
                      <Button
                        disabled={loading}
                        className="cutsom-bttn"
                        type="submit"
                      >
                        {loading ? (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="lg"
                            className="spinner-icon"
                          />
                        ) : (
                          "Update Password"
                        )}
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="sign-box-wrap">
              <Row>
                <Col lg="12" className="text-center">
                  <img
                    className="forgot-password-icon"
                    src={ChangePasswordIcon}
                    alt=""
                  />
                  <h2 className="text-center">Verifying Email Address...</h2>
                  
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default ChangePassword;
