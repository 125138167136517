import React, {useState} from "react";
import { Row, Col } from 'react-bootstrap'
import Auth from '../assets/images/blod-details/icon-1.svg'
import Date from '../assets/images/blod-details/icon-2.svg'
import Share from '../assets/images/blod-details/icon-3.svg'
import View from '../assets/images/blod-details/icon-4.svg'
import BlogOne from '../assets/images/blod-details/blog-details-1.png'
// import BlogTwo from '../assets/images/blod-details/blog-details-2.png'
import ShareIcon from '../assets/images/blod-details/share.svg'
import LeftIcon from '../assets/images/blod-details/left-arrow.svg'
import RightICon from '../assets/images/blod-details/right-arrow.svg'
// import BlogDetailsProduct from '../assets/images/blod-details/blog-details-product.png'
import { Link } from 'react-router-dom'
import { formatPrice, formatDate, numberFormatter } from '../helpers/helpers';
import SocialSharingModal from '../component/SocialSharingModal';

function BlogDetailComp(props) {

  const [showSocialShareModal, setShowSocialShareModal] = useState(false);
  const webUrl = window.location.href; // Your web URL to be shared

  const openSocialShareModal = () => setShowSocialShareModal(true);
  const closeSocialShareModal = () => setShowSocialShareModal(false);

  
  return (
    <>
     <section className='blog-details'>        
        <Row>
            <Col lg="8">
              <div className="blog-details-box">
                <h1>{props.blogDetails?.title}</h1>
                <div className='blog-info'>
                  <div className="blog-info-item">
                    <span><img src={Auth} alt="" /></span>
                    <p>by {props.blogDetails?.author}</p>
                  </div>
                  <div className="blog-info-item">
                    <span><img src={Date} alt="" /></span>
                    <p>{formatDate(props.blogDetails?.created_at)}</p>
                  </div>
                  <div className="blog-info-item">
                    <span><img src={Share} alt="" /></span>
                    <p>Share</p>
                  </div>
                  <div className="blog-info-item">
                    <span><img src={View} alt="" /></span>
                    <p>{numberFormatter(props.blogDetails?.view_counts)} Views</p>
                  </div>
                </div>
                <img className='blog-img' src={BlogOne} alt="" />
                <div className="post__content" dangerouslySetInnerHTML={{__html: props.blogDetails?.description}}></div>
                


              </div>
              <div className="share-group">
                <h2>Share this article with your friends</h2>
                <button  onClick={openSocialShareModal} className='cutsom-bttn'>Share <img src={ShareIcon} alt="" /></button>
              </div>

              <div className="prev-next-group">
                {(props.blogDetails?.previous) &&
                <div className="prev-group">
                  <Link className="prev-next-icon" to={`/blog-details/${props.blogDetails.previous.slug}`}><img src={LeftIcon} alt="" /></Link>
                  <Link to={`/blog-details/${props.blogDetails.previous.slug}`} className="prev-next-details">
                    <span>Previous Article</span>
                    <p>{props.blogDetails?.previous.title}</p>
                  </Link>
                </div>
                }

                {(props.blogDetails?.next) &&
                <div className="next-group">
                  <Link to={`/blog-details/${props.blogDetails.next.slug}`} className="prev-next-details">
                    <span>Next Article</span>
                    <p>{props.blogDetails?.next.title}</p>
                  </Link>
                  <Link to={`/blog-details/${props.blogDetails.next.slug}`} className="prev-next-icon"><img src={RightICon} alt="" /></Link>
                </div>
                }

              </div>
            </Col>
            <Col lg="4">
              <div className="blog-details-sidebar">
                <div className="blog-details-sidebar-header">
                  <h2>SHOP THIS ARTICLE</h2>
                </div>

                {(props.blogDetails?.products) && (
                <div className="blog-details-sidebar-body">
                    {props.blogDetails?.products?.map((product, index) => (
                    <Link className="blog-details-sidebar-item" key={index} to={'/products/'+product.slug}>
                      <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${product.image}`} alt="" />
                      <div className="product-item-text">
                          <p className='category'>{product.category_title}</p>
                          <h3>{product.title}</h3>                          
                          <div className="price-box">
                            <span>Price</span>
                            <div className='price-value'>
                              <p className='new-price'>{product.currency+' '+formatPrice(product.sale_price)}</p>
                              <del className='old-price'>{product.currency+' '+formatPrice(product.regular_price)}</del>
                            </div>
                          </div>           
                        </div>
                    </Link>
                    ))}
                </div>
                )}
                
              
              </div>
            </Col>
        </Row>
     </section>     

      <SocialSharingModal
        showSocialShareModal={showSocialShareModal}
        closeSocialShareModal={closeSocialShareModal}
        webUrl={webUrl}
      />
    </>
  )
}
export default BlogDetailComp