import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './component/Footer';
import ScrollToTop from './component/ScrollToTop';

// Import your components
import Home from './pages/Home';
import HomeOne from './pages/HomeOne';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import Products from './pages/Products';
import ProductDetails from './pages/ProductDetails';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import LegalPolicies from './pages/LegalPolicies';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';
import FAQ from './pages/FAQ';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import MyAccount from './pages/MyAccount';
import Career from './pages/Career';

import ProtectedRoute from './component/ProtectedRoute';


function App() {
  return (
    <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/sign-in" element={<SignIn />} /> 
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />  
          <Route path="/verify-email" element={<ChangePassword />} />  
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blog-details/:slug" element={<BlogDetails />} />
          <Route path="/faqs" element={<FAQ />} />
          <Route path="/contact-us" element={<ContactUs />} />

         <Route path="/home-1" element={<HomeOne />} /> 
                  
          <Route path="/products" element={<Products />} />
          <Route path="/products/:slug" element={<ProductDetails />} />
         
          <Route path="/page/:slug" element={<LegalPolicies />} />
          <Route path="/about-us" element={<AboutUs />} />
          
          <Route path="/cart" element={<Cart />} />
          {/* <Route path="/cart/checkout" element={<Checkout />} /> */}  
          <Route path="/cart/checkout" element={<ProtectedRoute element={Checkout} />} />

          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/careers" element={<Career />} />

          {/* <Route path="/profile" element={<MyAccount />} /> */}
          <Route path="/profile" element={<ProtectedRoute element={MyAccount} />} />
          <Route path="/profile/:innerPage" element={<ProtectedRoute element={MyAccount} />} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
