import React from 'react'
import SubBanner from '../component/SubBanner'  
import ShoppingCart from '../component/ShoppingCart' 
// import MedicineHealthProducts from '../component/MedicineHealthProducts' 
// import OfferSection from '../component/OfferSection' 
// import PowerNature from '../component/PowerNature' 
// import OurBlogArticle from '../component/OurBlogArticle' 
import ATC from '../component/ATC' 
import Header from '../component/Header'
function Cart() {
  return (
    <>
      <div className='main cart-page'>
      <Header /> 
        <SubBanner HeaderTitle="Cart" HeaderSubtitle=""/> 
        <ShoppingCart />       
        {/* 
        <MedicineHealthProducts />
        <OfferSection /> 
        <OurBlogArticle blogTitle="Our Blog & Article" />
        */}
        <ATC />
      </div>
    </>
  )
}
export default Cart


