import React from 'react'
import { Row, Col } from 'react-bootstrap'
import CareerForm from '../component/CareerForm'
import arrowDown from '../assets/images/arrow-down.svg'
import { Accordion } from 'react-bootstrap'
import Experience from '../assets/images/experience.svg'
import WorkingHours from '../assets/images/working-hours.svg'
import WorkingDays from '../assets/images/working-days.svg'
import Salary from '../assets/images/salary.svg'
import Vacancy from '../assets/images/vacancy.svg'
import Deadline from '../assets/images/deadline.svg'
import careerWhyWorkWithImg from '../assets/images/career-why-work-with-img.png'
import { Link } from 'react-router-dom'
import whiteArrow from '../assets/images/button-arrow.svg'
function CareerComp() {
  return (
    <>
     <section className='career'>
        <div className="common-header">
            <h2>Current Openings</h2>
            <p>We're always looking for talented people</p>
        </div>
        <Row>
          <Col>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="opening-header">
                      <div className="opening-text">
                        <h2>Senior Back End Data Developer</h2>
                        <div className="jld-group">
                          <p>Job Type : <b>Back End Developer</b></p>
                          <p>Location : <b>Remote</b></p>
                          <p>Date : <b>18 April 2024  11:32 PM</b></p>
                        </div>
                      </div>
                      <div className="opening-btn">
                        <p>Jobs Details <img src={arrowDown} alt="" /></p>
                        {/* <Link to="postOne" className='cutom-button'>Apply Now <img className='on-hover' src={buttonArowWhite} alt="" /> <img className='defailt' src={buttonArowWhite2} alt="" /></Link>  */}
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="opening-body">
                      <ul>
                        <li>
                          <img src={Experience} alt="" />
                          <span>Experience</span>
                          <p>10 Years Experience</p>
                        </li>
                        <li>
                          <img src={WorkingHours} alt="" />
                          <span>Working Hours</span>
                          <p>8 Hours</p>
                        </li>
                        <li>
                          <img src={WorkingDays} alt="" />
                          <span>Working Days</span>
                          <p>5 Days</p>
                        </li>
                        <li>
                          <img src={Salary} alt="" />
                          <span>Salary</span>
                          <p>Open</p>
                        </li>
                        <li>
                          <img src={Vacancy} alt="" />
                          <span>Vacancy</span>
                          <p>3 Vacancies</p>
                        </li>
                        <li>
                          <img src={Deadline} alt="" />
                          <span>Deadline</span>
                          <p>27 July 2024</p>
                        </li>
                      </ul>
                      <ul className="opening-list">
                        <li>Demonstrate a minimum of ten (10) years’ of experience as a Subject Matter Expert in Data Analysis and Back End Development</li>
                        <li>Have the ability to work independently with minimal oversight, innovative, a self-starter, flexible, articulate, and an effective communicator - both written and orally, providing thorough and completed projects that require only minor revisions and editing.</li>
                        <li>Have at least ten (10) years’ experience supporting office and business process automation using a variety of business productivity tools such as O365 or similar to collate data and streamline processes.</li>
                        <li>Have at least three (3) years’ experience using JavaScript, Python, JSON/ JSONP, REST calls, and SQL for the modification and manipulation of new or existing APIs to support integration with new and existing capabilities.</li>
                        <li>Have at least ten (10) years’ experience in using web design and available development tools such as Notepad++, SharePoint Designer or related design tool with working knowledge of Visual Studio, a plus.</li>
                        <li>A minimum three (3) years’ experience creating customized workflows and web-based forms to include experience improving business processes and automating the collection, organization, dissemination and archival of intelligence products using XSLT, XSL-FO, Java, JavaScript, Perl, Python, shell scripting, and/or node.js.</li>
                        <li>A minimum three (3) years’ experience with data security management on DoD classified networks with both client-side and server side applications.</li>
                        <li>Ability to use authentication security features and protocols when creating and/or modifying new and existing projects.</li>
                        <li>Ability of creation, maintenance, updating and trouble-shooting of applications in a cloud-based environment, Security+ certification is highly desired. These skills and insight will guide and implement innovative advances with cloud computing services for the J2 organization.</li>
                        <li>A minimum of three (3) years’ experience integrating data from two (2) or more data sources into a single location on dashboards with integrated, filterable, and searchable features to access data.</li>
                        <li>Have at least three (3) years’ experience conducting customer surveys to gather feedback and requirements in order to improve processes and applications to increase information sharing and collaboration among geographically separated organizations.</li>
                        <li>Have at least three (3) years’ with analysis, problem solving, and the ability to troubleshoot technical issues associated with individual web development efforts, web page rendering, latency and authentication/access issues.</li>
                        <li>A minimum of three (3) years’ experience performing administrative duties in a joint environment.</li>
                        <li>Have experience experience with Microsoft Office applications to include Word, PowerPoint, Outlook, Excel, and SharePoint 2019 / M365 or later, as well as experience or training administering web services for information management and knowledge management functions.</li>
                        <li>A minimum of three (3) years’ experience providing training and educating customer base on existing, newly established, and implemented applications, to include documentation such as training materials, Standard Operating Procedures (SOPs), and flowcharts.</li>
                        <li>At least three (3) years’ experience using technology to streamline unique business processes for the overall benefit of an organization. Demonstrated experience with Information Management (IM) / KM intelligence tools, processes, and activities is highly desired.</li>                      

                      </ul>
                      <div id="postOne">
                        <CareerForm />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item >
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <div className="opening-header">
                      <div className="opening-text">
                        <h2>Senior Back End Data Developer</h2>
                        <div className="jld-group">
                          <p>Job Type : <b>Back End Developer</b></p>
                          <p>Location : <b>Remote</b></p>
                          <p>Date : <b>18 April 2024  11:32 PM</b></p>
                        </div>
                      </div>
                      <div className="opening-btn">
                        <p>Jobs Details <img src={arrowDown} alt="" /></p>
                        {/* <Link to="postTwo" className='cutom-button'>Apply Now <img className='on-hover' src={buttonArowWhite} alt="" /> <img className='defailt' src={buttonArowWhite2} alt="" /></Link>  */}
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="opening-body">
                      <ul>
                        <li>
                          <img src={Experience} alt="" />
                          <span>Experience</span>
                          <p>10 Years Experience</p>
                        </li>
                        <li>
                          <img src={WorkingHours} alt="" />
                          <span>Working Hours</span>
                          <p>8 Hours</p>
                        </li>
                        <li>
                          <img src={WorkingDays} alt="" />
                          <span>Working Days</span>
                          <p>5 Days</p>
                        </li>
                        <li>
                          <img src={Salary} alt="" />
                          <span>Salary</span>
                          <p>Open</p>
                        </li>
                        <li>
                          <img src={Vacancy} alt="" />
                          <span>Vacancy</span>
                          <p>3 Vacancies</p>
                        </li>
                        <li>
                          <img src={Deadline} alt="" />
                          <span>Deadline</span>
                          <p>27 July 2024</p>
                        </li>
                      </ul>
                      <ul className="opening-list">
                        <li>Demonstrate a minimum of ten (10) years’ of experience as a Subject Matter Expert in Data Analysis and Back End Development</li>
                        <li>Have the ability to work independently with minimal oversight, innovative, a self-starter, flexible, articulate, and an effective communicator - both written and orally, providing thorough and completed projects that require only minor revisions and editing.</li>
                        <li>Have at least ten (10) years’ experience supporting office and business process automation using a variety of business productivity tools such as O365 or similar to collate data and streamline processes.</li>
                        <li>Have at least three (3) years’ experience using JavaScript, Python, JSON/ JSONP, REST calls, and SQL for the modification and manipulation of new or existing APIs to support integration with new and existing capabilities.</li>
                        <li>Have at least ten (10) years’ experience in using web design and available development tools such as Notepad++, SharePoint Designer or related design tool with working knowledge of Visual Studio, a plus.</li>
                        <li>A minimum three (3) years’ experience creating customized workflows and web-based forms to include experience improving business processes and automating the collection, organization, dissemination and archival of intelligence products using XSLT, XSL-FO, Java, JavaScript, Perl, Python, shell scripting, and/or node.js.</li>
                        <li>A minimum three (3) years’ experience with data security management on DoD classified networks with both client-side and server side applications.</li>
                        <li>Ability to use authentication security features and protocols when creating and/or modifying new and existing projects.</li>
                        <li>Ability of creation, maintenance, updating and trouble-shooting of applications in a cloud-based environment, Security+ certification is highly desired. These skills and insight will guide and implement innovative advances with cloud computing services for the J2 organization.</li>
                        <li>A minimum of three (3) years’ experience integrating data from two (2) or more data sources into a single location on dashboards with integrated, filterable, and searchable features to access data.</li>
                        <li>Have at least three (3) years’ experience conducting customer surveys to gather feedback and requirements in order to improve processes and applications to increase information sharing and collaboration among geographically separated organizations.</li>
                        <li>Have at least three (3) years’ with analysis, problem solving, and the ability to troubleshoot technical issues associated with individual web development efforts, web page rendering, latency and authentication/access issues.</li>
                        <li>A minimum of three (3) years’ experience performing administrative duties in a joint environment.</li>
                        <li>Have experience experience with Microsoft Office applications to include Word, PowerPoint, Outlook, Excel, and SharePoint 2019 / M365 or later, as well as experience or training administering web services for information management and knowledge management functions.</li>
                        <li>A minimum of three (3) years’ experience providing training and educating customer base on existing, newly established, and implemented applications, to include documentation such as training materials, Standard Operating Procedures (SOPs), and flowcharts.</li>
                        <li>At least three (3) years’ experience using technology to streamline unique business processes for the overall benefit of an organization. Demonstrated experience with Information Management (IM) / KM intelligence tools, processes, and activities is highly desired.</li>                      

                      </ul>
                      <div id="postTwo">
                        <CareerForm />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item >
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <div className="opening-header">
                      <div className="opening-text">
                        <h2>Senior Back End Data Developer</h2>
                        <div className="jld-group">
                          <p>Job Type : <b>Back End Developer</b></p>
                          <p>Location : <b>Remote</b></p>
                          <p>Date : <b>18 April 2024  11:32 PM</b></p>
                        </div>
                      </div>
                      <div className="opening-btn">
                        <p>Jobs Details <img src={arrowDown} alt="" /></p>
                        {/* <Link to="postThree" className='cutom-button'>Apply Now <img className='on-hover' src={buttonArowWhite} alt="" /> <img className='defailt' src={buttonArowWhite2} alt="" /></Link>                         */}
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="opening-body">
                      <ul>
                        <li>
                          <img src={Experience} alt="" />
                          <span>Experience</span>
                          <p>10 Years Experience</p>
                        </li>
                        <li>
                          <img src={WorkingHours} alt="" />
                          <span>Working Hours</span>
                          <p>8 Hours</p>
                        </li>
                        <li>
                          <img src={WorkingDays} alt="" />
                          <span>Working Days</span>
                          <p>5 Days</p>
                        </li>
                        <li>
                          <img src={Salary} alt="" />
                          <span>Salary</span>
                          <p>Open</p>
                        </li>
                        <li>
                          <img src={Vacancy} alt="" />
                          <span>Vacancy</span>
                          <p>3 Vacancies</p>
                        </li>
                        <li>
                          <img src={Deadline} alt="" />
                          <span>Deadline</span>
                          <p>27 July 2024</p>
                        </li>
                      </ul>
                      <ul className="opening-list">
                        <li>Demonstrate a minimum of ten (10) years’ of experience as a Subject Matter Expert in Data Analysis and Back End Development</li>
                        <li>Have the ability to work independently with minimal oversight, innovative, a self-starter, flexible, articulate, and an effective communicator - both written and orally, providing thorough and completed projects that require only minor revisions and editing.</li>
                        <li>Have at least ten (10) years’ experience supporting office and business process automation using a variety of business productivity tools such as O365 or similar to collate data and streamline processes.</li>
                        <li>Have at least three (3) years’ experience using JavaScript, Python, JSON/ JSONP, REST calls, and SQL for the modification and manipulation of new or existing APIs to support integration with new and existing capabilities.</li>
                        <li>Have at least ten (10) years’ experience in using web design and available development tools such as Notepad++, SharePoint Designer or related design tool with working knowledge of Visual Studio, a plus.</li>
                        <li>A minimum three (3) years’ experience creating customized workflows and web-based forms to include experience improving business processes and automating the collection, organization, dissemination and archival of intelligence products using XSLT, XSL-FO, Java, JavaScript, Perl, Python, shell scripting, and/or node.js.</li>
                        <li>A minimum three (3) years’ experience with data security management on DoD classified networks with both client-side and server side applications.</li>
                        <li>Ability to use authentication security features and protocols when creating and/or modifying new and existing projects.</li>
                        <li>Ability of creation, maintenance, updating and trouble-shooting of applications in a cloud-based environment, Security+ certification is highly desired. These skills and insight will guide and implement innovative advances with cloud computing services for the J2 organization.</li>
                        <li>A minimum of three (3) years’ experience integrating data from two (2) or more data sources into a single location on dashboards with integrated, filterable, and searchable features to access data.</li>
                        <li>Have at least three (3) years’ experience conducting customer surveys to gather feedback and requirements in order to improve processes and applications to increase information sharing and collaboration among geographically separated organizations.</li>
                        <li>Have at least three (3) years’ with analysis, problem solving, and the ability to troubleshoot technical issues associated with individual web development efforts, web page rendering, latency and authentication/access issues.</li>
                        <li>A minimum of three (3) years’ experience performing administrative duties in a joint environment.</li>
                        <li>Have experience experience with Microsoft Office applications to include Word, PowerPoint, Outlook, Excel, and SharePoint 2019 / M365 or later, as well as experience or training administering web services for information management and knowledge management functions.</li>
                        <li>A minimum of three (3) years’ experience providing training and educating customer base on existing, newly established, and implemented applications, to include documentation such as training materials, Standard Operating Procedures (SOPs), and flowcharts.</li>
                        <li>At least three (3) years’ experience using technology to streamline unique business processes for the overall benefit of an organization. Demonstrated experience with Information Management (IM) / KM intelligence tools, processes, and activities is highly desired.</li>                      

                      </ul>
                      <div id="postThree">
                        <CareerForm />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item >
            </Accordion>
          </Col>
        </Row>
     </section>
      <section className='about-section bg-primary-light padding-100'>        
        <Row className='align-items-center'>
          <Col md="6" lg="6" className=' order-2 order-md-1'>
              <div className="about-section-img">
                <img src={careerWhyWorkWithImg} alt="" />
              </div>              
          </Col>
          <Col md="6" lg="6" className='order-1 order-md-2'>
              <div className="about-section-text pr-100">
                  <span>Why Work With</span>
                  <h3>Smart Shopping</h3>
                  <p>What sets us apart from other service providers is our commitment to personalization, community engagement, and open-mindedness. We understand that each person needs are unique, and we offer tailored solutions that resonate with their individual circumstances. Beyond our dedication to serving our members, we extend our support to the wider community, embodying a strong sense of responsibility to make a positive impact. Embracing diverse perspectives and innovative approaches, we foster an open-minded culture that continually seeks progressive solutions to evolving challenges. With trust, integrity, and a passion for what we do, we stand out as a provider dedicated to not only safeguarding your well-being but also enriching your life and the lives of those around you.</p>
                  <Link href="/career" className='cutsom-bttn'>Join Our Team <img src={whiteArrow} alt="" /></Link>
              </div>
            </Col>   
            
                    
        </Row>
     </section> 
    </>
  )
}
export default CareerComp